
import Kaudulla1 from '../../../images/history/kaudulla1.jpg'
import Kaudulla2 from '../../../images/history/kaudulla2.jpg'
import ImageBox from './ImageBox'

const Kaudulla = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Kaudulla</div>

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        Opened in 2002, Kaudulla is the newly established national park in the heart of the cultural triangle. Situated around the ancient Kaudulla tank, the park provides a 6656 hectare corridor for elephants between Somawathiya National Park and Minneriya National Park.

                        Kaudulla National Park offers fantastic chance of getting up close with elephants. In October, there are up to 250 elephants in the park, including herds of juvenile males. There are also leopards, fishing cats, sambar deer, endangered rusty spotted cats and sloth bears.
                        <br></br><br></br>
                        The park stands on the fringe of the ancient Kaudulla Wewa irrigation tank, 6km off the Habarana–Trincomalee Road at Gal Oya junction. It is visited on a jeep safari from Habarana, Polonnaruwa, Sigiriya or Dambulla. As an additional novelty, you may go for catamaran rides on the tank.

                        The best time to visit Kaudulla National Park is between August and December, with the population of elephants peaking to over 200 in September to October, following the period of the famous elephant gathering at Minneriya National Park. However, the Elephants could be seen in either one of these parks during any time of the year.

                    </div>
                </div>

                <div className='attraction-park-box'>
                    <h3>Climate and vegetation in Kaudulla National Park</h3>
                    <div className="attraction-park-discription">
                        Located 84km from Anuradhapura as the dry period persists from April to October. The park consists of many plant and grass species grow well during the rainy season whilst an abundance of food and water, even in the dry period, attracts a large number of herbivorous mammals to the park. Moreover, the vegetation of the park represents Sri Lanka's dry evergreen forests.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Wildlife in Kaudulla</h3>
                    <div className="attraction-park-discription">

                        The best time to visit Kaudulla National Park is between August and December, with the population of elephants peaking to over 200 in September to October.
                        <br></br><br></br>
                        Kaudulla National Park is home to 23 species of mammals including Sambar Deer, Sri Lankan Axis Deer, Chevrotain, Wild boar, Leopard, and Sloth Bear are other mammals. Very rarely one could spot leopard and sloth bears. The best period of time to visit this park is during the months of June to September. Tourists find a ride in Paddle-boats on the lake an excellent opportunity to enjoy bird watching.
                        <br></br><br></br>
                        More than 160 species of bird including large water birds such as Spot-billed Pelican and Lesser Adjutant visit the Kaudulla tank. Asian Spoonbill, Grey-headed Fish Eagle, Black-headed Ibis, Painted Stork and Openbill Stork are common sights at the park. Peacock, Eagle, Giant Eagle add to the significance of the park. One of the most spectacular sceneries in the park is hundreds of pelicans at the tank when
                        <br></br><br></br>
                        it is about to sunset.
                    </div>

                    <ImageBox imageUrl={Kaudulla1} />
                </div>
                <div className='attraction-park-box'>
                    <h3> Safari at Kaudulla</h3>
                    <div className="attraction-park-discription">

                        There are 3 safari options available for the guest.
                        <br></br><br></br>
                        1.  Morning Safari (6.00am)<br></br>
                        2.  Afternoon Safari (2.30pm)<br></br>
                        3.  Full day Safari
                        <br></br><br></br>
                        The safari would take place with an experienced guide in specially modified comfortable jeeps for unique safari experience. The tour could be more customized for guests for their preferences (Family safari, honeymoon safari, photography safari etc.).

                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Tour in brief</h3>
                    <div className="attraction-park-discription">
                        A brief of the tour will take place in the entrance to the Kaudulla Safari. For the morning half-day tour, the starting time will be at 6 a.m. while the afternoon tour begins at 2.30 p.m.
                        <br></br><br></br>
                        After entering the national park with your guide in a jeep, you will be able to spot several species of birds and other animals that have made their homes in this sanctuary. The guide will explain about the various interesting attributes as you will learn and enjoy about the flora and fauna of Kaudulla.
                        <br></br><br></br>
                        You will be taken through most of the Kaudulla National Park, covering the wetlands, grasslands, forest patches, etc. We will make longer stops at certain watering holes for unique photography opportunities.
                        <br></br><br></br>
                        Finishing the safari, either round 11 a.m. or around 6 p.m. you will be dropped at the park entrance.

                    </div>
                    <ImageBox imageUrl={Kaudulla2} />
                </div>

            </div>
        </div>
    )
}

export default Kaudulla
