// import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#206A5D',
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#81B214",
      },
    },
  });

root.render(    
    <ThemeProvider  theme={theme}>
        <App />
    </ThemeProvider>
);