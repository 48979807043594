import { Alert, Button, useMediaQuery, useTheme } from '@mui/material'
import './selectablepackage.css'
import SelectModal from './SelectModal';

const selectebleBtnStyle = {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100px'
}






export const SelectablePackage = ({ code, title, packageTitle, packageDiscription, packagePrice, setCart, setPrice, available }) => {

    // console.log(code, title, packageTitle, packageDiscription, packagePrice);

    function handlerSelectedRoom() {

        // console.log(available);

        setCart({
            type: 'select',
            code: code,
            packageTitle: packageTitle,
            title: title,
            price: packagePrice
        })

        setPrice({
            type: 'price',
            price: packagePrice
        })
    }


    const theme = useTheme();
    const mq_xs = useMediaQuery(theme.breakpoints.only("xs"));


    return (
        <div className='selectablepackage'>
            <div className="selectable-details-box">
                <p className="selectable-title">{packageTitle}</p>
                {
                    mq_xs ? '' : <p className="selectable-details-item"> {packageDiscription} </p>
                }

            </div>
            <div className="selectable-button-box">
                <p className="selectable-price">US${packagePrice}</p>
                <p className="selectable-price-details">Two Person/Night</p>
                {/* <Button variant='contained' sx={selectebleBtnStyle} onClick={handlerSelectedRoom} size='small'>Select</Button> */}
                <SelectModal handler={handlerSelectedRoom} available={available} />
            </div>
        </div>
    )
}
