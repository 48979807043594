import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import './gallery.css'

import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import target from '../../db/target';
import axios from 'axios';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Background from 'hero-slider/dist/components/Slide/Background';

export const Gallery = () => {

    const [allImages, setAllImages] = useState([])

    const [data, setData] = useState({ img: '', i: 0 })

    const viewImage = (img, i) => {
        // console.log(img, i);
        setData({ img, i })
    }

    const imageAction = (action) => {
        let i = data.i;
        if (action === 'next-image') {
            setData({ img: allImages[i + 1], i: i + 1 })
        }
        if (action === 'previous-image') {
            setData({ img: allImages[i - 1], i: i - 1 })
        }
        if (!action) {
            setData({ img: '', i: 0 })
        }

    }

    useEffect(() => {
        axios.get(target + 'api/client/gallery/all')
            .then(res => {

                const data = res.data.filter(img => {
                    return img.state === 'gallery-1'
                })

                setAllImages(data.map(item => {
                    return item.name
                }))
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const endPoint = target + 'images/rooms/'


    return (
        <div className='gallery-page'>
            <div className="gallery-box">
                <h1>Gallery</h1>
                <Box sx={{ width: 1, height: 1 }}>
                    {/* <ImageList variant="masonry" cols={3} gap={8}>
                        {allImages.map((img) => (
                            <ImageListItem key={img}>
                                <img
                                    src={`${endPoint + img}`}
                                    srcSet={`${endPoint + img}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList> */}

                    {
                        data.img &&
                        <div className='gallery-img-box' style={{
                            width: '100%',
                            height: '100vh',
                            background: 'rgb(0, 0, 0, 0.9)',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            overflow: 'hidden'
                        }}>
                            <button onClick={() => imageAction()} style={{ position: 'absolute', top: '20px', right: '20px', width: '26px', height: '26px', borderRadius: '13px', background: 'red', color: 'white', border: 'none' }}>X</button>
                            <button className='gallery-img-box-1' onClick={() => imageAction('previous-image')} style={{ marginRight: '10px', width: '30px', height: '50px', background: '#206A5D', border: 'none', borderRadius: '10px', color: 'white' }}>{'<'}</button>
                            <img src={endPoint + data.img} style={{ width: 'auto', maxWidth: '90%', maxHeight: '90%' }} />
                            <div className='gallery-img-box-div'>
                                <button className='gallery-img-box-2' onClick={() => imageAction('previous-image')} style={{ marginRight: '10px', width: '30px', height: '50px', background: '#206A5D', border: 'none', borderRadius: '10px', color: 'white' }}>{'<'}</button>
                                <button onClick={() => imageAction('next-image')} style={{ marginLeft: '10px', width: '30px', height: '50px', background: '#206A5D', border: 'none', borderRadius: '10px', color: 'white' }}>{'>'}</button>

                            </div>
                        </div>
                    }

                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                    >
                        <Masonry gutter='5px'>
                            {allImages.map((image, i) => (
                                <img
                                    key={i}
                                    src={`${endPoint + image}`}
                                    style={{ width: "100%", display: "block" }}
                                    alt=""
                                    onClick={() => viewImage(image, i)}
                                />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>

                </Box>
            </div>
        </div>
    )
}
