import { Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CheckBookCpt = ({bookingId, email}) => {
    return (
        <div className='checkout-book-cpt'>
            <div style={{textAlign: 'center'}}>
                <Typography variant='h5'>Booking-ID: <span style={{fontWeight: 'bold'}}>#{bookingId}</span></Typography>
                <Typography variant='h6'>Your Booking Completed! </Typography>
            </div>
            <div style={{textAlign: 'center', marginTop: '10px'}}>
                <CheckCircleOutlineIcon sx={{ fontSize: '64px', color: '#206A5D' }} />
                <Typography variant='h3' sx={{ color: '#206A5D', fontWeight: 900 }}>Thank You!</Typography>
            </div>
            <Typography variant='h6' sx={{textAlign: 'center'}}>Your booking details has been sent to <span style={{fontWeight: 'bold'}}>"{email}"</span></Typography>
        </div>
    )
}

export default CheckBookCpt
