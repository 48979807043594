import React from 'react'

const AttractionCardXS = ({ data }) => {
    const { imageUrl, left, title, description } = data

    return <div className='attraction-card-box-xs'>
        <div className="attraction-card-title-xs">{title}</div>
        {
            imageUrl !== ''
                ?
                <div className="attraction-card-img-box-xs">
                    <img src={imageUrl} />
                </div>
                :
                ''
        }
        <div className="attraction-card-msg-box-xs">
            <div className="attraction-card-description-xs">{description}</div>
        </div>
    </div>

}

export default AttractionCardXS
