import './attraction.css'
import Image from '../../images/hero/Anuradhapura.jpg'
import AttractionCard from './AttractionCard'
import attractions from './attractions'
import { Box } from '@mui/material'
import AttractionCardXS from './AttractionCardXS'
import Trincomalee from './Locations/Trincomalee'
import Jaffna from './Locations/Jaffna'
import Mannar from './Locations/Mannar'
import Polonnaruwa from './Locations/Polonnaruwa'
import Wilpattu from './Locations/Wilpattu'
import Minneriya from './Locations/Minneriya'
import Isurumuniya from './Locations/Isurumuniya'
import Mihintale from './Locations/Mihintale'
import Kaudulla from './Locations/Kaudulla'

const Visit = () => {


    return (
        <Box className='attraction-page'>
            <Box className="attraction-hero-section">
                {/* <Box className="attraction-hero-img"> */}
                {/* <img src={Image} alt="bg" className="attraction-bg-img" /> */}
                {/* </Box> */}
                <Box className="attraction-hero-msg-box">
                    <p className="attraction-hero-title">Anuradhapura – Sacred City</p>
                    <p className="attraction-hero-desciption">Anuradhapura is the first capital of Sri Lanka located in north central province of Sri Lanka. It is one of the ancient capitals of Sri Lanka which was the center of Theravada Buddhism for many centuries. Due to its ruins of an ancient Sri Lankan civilization UNESCO named it as a UNESCO world heritage site in 1982 under the name of Sacred City of Anuradhapura.</p>
                </Box>
                <Box className="attraction-history-box-section">
                    <Box className="attaction-history-box">
                        <p className="attraction-history-title">History of Anuradhapura sacred city</p>
                        <p className="attraction-history-desciption">Anuradhapura is greatest monastic city of the ancient world that dates back to 5th Century BC remained the majestic seat of the kingdom of Sri Lanka until the 11th century A.D. Anuradhapura flourished as the Capital of Lanka from 377 BC to the end of the reign 1017 AD and was the Royal Seat for 1400 years under 130 kings as the capital of the Country.</p>
                    </Box>
                </Box>
            </Box>
            <Box className="attraction-body">
                {/* <Box className="attraction-body-title-box">
                    <p className="attraction-body-title">EIGHT SACRED PLACES <br ></br> (Atamasthanaya)</p>
                </Box> */}

                <Box sx={{ display: { xs: 'block', md: 'none' } }}  >
                    {
                        attractions.map((data, index) => {

                            if (data.title === "Mihintale") {
                                return <Mihintale key={index} />
                            } else if (data.title === "Isurumuniya") {
                                return <Isurumuniya key={index} />
                            } else {
                                return <AttractionCardXS key={index} data={data} />
                            }

                        })
                    }
                </Box>

                <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                    {
                        attractions.map((data, index) => {

                            if (data.title === "Mihintale") {
                                return <Mihintale key={index} />
                            } else if (data.title === "Isurumuniya") {
                                return <Isurumuniya key={index} />
                            } else {
                                return <AttractionCard key={index} data={data} />
                            }


                        })
                    }
                </Box>


                <Minneriya />
                <Kaudulla />

                <Wilpattu />
                <Polonnaruwa />
                <Mannar />
                <Jaffna />
                <Trincomalee />

            </Box>

        </Box>
    )
}

export default Visit
