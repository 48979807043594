import { Box } from '@mui/material'
import React from 'react'

const ImageBoxCard = ({imageUrl, width}) => {
    return (
        <div>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}  >
                {
                    imageUrl !== ''
                        ?
                        <div className="attraction-card-img-box-xs"  >
                            <img src={imageUrl}  style={{borderRadius: '10px', margin: "0"}}/>
                        </div>
                        :
                        ''
                }
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
                {
                    imageUrl !== ''
                        ?
                        <div className="attraction-card-img-box" >
                            <img src={imageUrl} style={{ width: '100%', margin: "0", borderRadius: '10px' }} />
                        </div>
                        :
                        ''
                }
            </Box>
        </div>
    )
}

export default ImageBoxCard
