import image1 from "../../images/attraction/11.jpg";
import image2 from "../../images/attraction/22.jpg";
import image3 from "../../images/attraction/33.jpg";
import image4 from "../../images/attraction/44.jpg";
import image5 from "../../images/attraction/55.jpg";
import image6 from "../../images/attraction/66.jpg";
import image7 from "../../images/attraction/77.jpg";
import image8 from "../../images/attraction/88.jpeg";
import image9 from "../../images/attraction2/Wessagiriya.png";
import image10 from "../../images/attraction2/Isurumuniya.png";
import image11 from "../../images/attraction2/TISSA.jpg";
import image12 from "../../images/attraction2/THANTHIRIMALE.jpg";
import image14 from "../../images/attraction2/Ritigala.png";
import imageb1 from "../../images/attraction/b11.jpg";
import SigiriyaImage from "../../images/history/sigiriya.png";
import DambullaImage from "../../images/history/Dambulla.jpg";
import VijithapuraImage from "../../images/history/Vijithapura.jpg";
import KalawewaImage from "../../images/history/Kalawewa.png";
import ResWeheraImage from "../../images/history/ResWehera.jpg";
import YapahuwaImage from "../../images/history/Yapahuwa.png";
import IsinbassagalaImage from "../../images/history/Isinbassagala.png";
import NachchaduwaImage from "../../images/history/Nachchaduwa.png";

const attractions = [
  {
    imageUrl: image2,
    left: true,
    bottom: false,
    title: " RUWANWELI MAHA SEYA",
    description:
      "\tRuwanwelisaya is one of the tallest Buddhist stupas with a height of 103 m and the most calming places in Sri Lanka. Been built in 140 BC by King Dutugamunu, this place has been a major tourist attraction housing some precious relics of Gauthama Buddha. There is even a statue of King Dutugamunu at the eastern entrance of Ruwanwelisaya. Interestingly, some say that the structure of the stupa came from the view of a bubble floating on the water. The structure represents the teachings of Buddha where the dome shows the vastness of the doctrine, the square on the dome stands for the four noble truths, the eight concentric rings for the Noble Eightfold Path and the large crystal for enlightenment.",
  },
  {
    imageUrl: image1,
    left: false,
    bottom: false,
    title: "JAYA SRI MAHA BODHI",
    description:
      "\tThe much revered and sacred Jaya Sri Maha Bodhiya (Bo Tree) – which originates from the Sri Maha Bodhiya Bo Tree, in Bodhi Gaya India, which is said to have provided shelter for Lord Buddha as he attained enlightenment – is venerated by thousands of Buddhist pilgrims who flock to Anuradhapura.\n\t Considered to be the oldest tree in recorded history (over 2,200 years old) and one of the most sacred relics for Buddhists all over the world, Jaya Sri Maha Bodhiya was brought to the country by Sanghamitta Thera and was planted by the then reigning monarch King Devanampiyatissa.\n\t Since the original Sri Maha Bodhiya in India was destroyed by a monarch of a different faith, thousands of devotees come from all over the world to Anuradhapura to worship the Bo tree.",
  },
  {
    imageUrl: image4,
    left: true,
    bottom: false,
    title: "LOVAMAHAPAYA ",
    description:
      "\tLocated in the historic town of Anuradhapura, Lovamahapaya, often referred to as Lohaprasadaya, translates to ‘brazen palace’, because of the bronze roof tiles that were once laid during its construction. The structure that stands now is a restoration that only took place recently, however, the original structure is believed to have been built in the 2nd century BC, during the reign of King Dutugemunu. Although there were taller structures at the time, such as the Abhayagiri Stupa and the Jetavanarama Stupa to name but two, the Lovamahapaya was the tallest building in the island from the 2nd century BC till the 10th century AD that boasted of nine stories and measured 400ft in length on one side.",
  },
  {
    imageUrl: image3,
    left: false,
    bottom: false,
    title: "THUPARAMAYA",
    description:
      "\tThuparamaya is closely linked to Mahinda Thera, the envoy sent by King Ashoka to Sri Lanka, bringing Theravada Buddhism and Chaitya worship to the island. At Mahinda's request, King Devanampiya Tissa built Thuparamaya to enshrine the right collar-bone of the Buddha, thus the claim to be the first stupa built in Sri Lanka. It also marks the formal arrival of Buddhism. ",
  },
  {
    imageUrl: image7,
    left: false,
    bottom: false,
    title: "ABHAYAGIRI DAGOBA ",
    description:
      '\tAbhayagiri Vihāra was a major monastery site of Mahayana, Theravada and Vajrayana Buddhism that was situated in Anuradhapura, Sri Lanka. It is one of the most extensive ruins in the world and one of the most sacred Buddhist pilgrimage cities in the nation. Historically it was a great monastic centre as well as a royal capital, with magnificent monasteries rising to many stories, roofed with gilt bronze or tiles of burnt clay glazed in brilliant colours. To the north of the city, encircled by great walls and containing elaborate bathing ponds, carved balustrades and moonstones, stood "Abhayagiri", one of seventeen such religious units in Anuradhapura and the largest of its five major viharas.',
  },
  {
    imageUrl: image8,
    left: false,
    bottom: false,
    title: "JETHAVANA DAGOBA",
    description:
      "\tAlthough very similar to the Abhayagiri Stupa, the Jetavanarama Stupa in Anuradhapura was built in the 3rd century AD. It is considered to be the largest stupa in the country and the world, and was once the foremost place of worship for Mahayana Buddhists (a branch of Buddhism). In fact, King Mahasen’s reason to build the stupa was to propagate Mahayana Buddhism over Theravada Buddhism. As a result, the time of building this stupa saw the kingdom experience a turbulent time, as it was on the verge of collapsing. However, the king, knowing his mistake, allowed the practice of Theravada Buddhism. Even today, the complex is a major place of worship as it serves as an Atamasthana.",
  },
  {
    imageUrl: image5,
    left: false,
    bottom: false,
    title: "LANKARAMAYA DAGOBA",
    description:
      "\tLankarama is a stupa built by King Valagamba, in an ancient place at Galhebakada in the ancient kingdom of Anuradhapura, Sri Lanka. Nothing is known about the ancient form of the stupa, and later this was renovated. The ruins show that there are rows of stone pillars and it is no doubt that there has been a house built encircling the stupa to cover it. The round courtyard of the stupa seems to be 10 feet above the ground. The diameter of the stupa is 45 feet. The courtyard is circular in shape and the diameter is 1332 feet.",
  },
  {
    imageUrl: image6,
    left: true,
    bottom: false,
    title: "MIRISAWETIYA DAGOBA",
    description:
      "\tMirisawetiya is one of the most ancient Stupas in Sri Lanka . Built by the Great King Dutugemunu. \n\tIt is believed that the great King Dutugemunu made many wishes here that have come true during his lifetime.\n\tIt is rumored that the King once forgot to offer a pod of chillie to the Monks before eating . As a token of apology, it is said that the King named the Stupa Mirisawetiya.",
  },
  {
    imageUrl: image9,
    left: false,
    bottom: true,
    title: "Wessagiriya",
    description:
      "\tVessagiriya is located about a mile south from the renowned Sri Maha Bodhi Tree. It is unmistakable when you get close to the site – you’ll see three clusters of jagged rock which acts as the centrepiece of an ancient monastery. It is believed that the monastery itself was a refuge not only for the clergy but for the common man as well. The very name “Vessagiriya” literally translates to “the rock shelter of the commoners”. \n\n\tVessagiriya has a close association with Isurumuniya – a prominent Buddhist temple found in nearby. As such, it is believed to have been of great importance to the Buddhist monks of ancient times. Along with the site, you’ll find a number of caves. These were thought to have been used by Buddhist monks as refuges back in ancient times. It is believed that these caves once harboured hundreds of monks who sought solace to meditate and seek wisdom in peace. Now, if you take time to explore the innards of these caves, you’ll come across several inscriptions. These signify the sponsors and donors of the Buddhist monks, their names forever etched upon the rock. These days, the caves are home to many animal species including swarms of bats and wasps.\n\n\tThe trail to Vessagiriya and its surrounding caves is a relatively short walk, but it boasts quite the scenery. From dramatic rock overhangs to verdant trees that reach up towards the heavens, you might just find yourself lost amidst all this natural beauty. If you’re particularly lucky, you might even come across some of the resident wildlife – few sights can match the regal beauty of a peacock perched high up on the tree branches.",
  },
  {
    imageUrl: image10,
    left: false,
    bottom: true,
    title: "Isurumuniya",
    description:""  },

  {
    imageUrl: image11,
    left: false,
    bottom: true,
    title: "Thissa Wewa",
    description:
      "\tBuilt by the very king who introduced Buddhism to the country, King Devanampiyatissa, in the 3rd century BC, the Tissa Wewa in Anuradhapura does not only showcase the ingenuity of the ancient engineers of the island, but also their practical approach to building irrigation. \n\nPurpose of Construction\n\n\tThe sole reason for the construction of the lake is to increase the water supply to the city, and to supply water to the Royal Gardens of Anuradhapura. The embankment of the ancient reservoir is believed to be 11,000 feet long and 25 feet high. Moreover, the width of the top of the reservoir was found to be 12 feet to 18 feet.\n\nSubsequent Additions and its Surroundings\n\n\tThe reservoir was worked on by the kings that came after Devanampiyatissa, such as King Bhatikabaya, who is believed to have installed a machine to pump water from the reservoir to the flowers in Ruwanveliseya. The reservoir is also known to provide water to the Isurumuniya Viharaya too. What makes it even more enticing to many, is its peaceful and rustic atmosphere that surrounds the lake. Out of all the lakes in the country, only two other lakes, namely the Abhaya Wewa and the Panda Wewa are older, as they are believed to have been built during the 4th and 5th centuries BC",
  },
  {
    imageUrl: "",
    left: false,
    bottom: true,
    title: "Mihintale",
    description: ""},
  {
    imageUrl: NachchaduwaImage,
    left: false,
    bottom: true,
    title: "Nachchaduwa Lake",
    description: "\tNachchaduwa Wewa is a reservoir which is situated in Anuradhapura. It is believed to be one of the sixteen large reservoirs built by King Mahasen who reigned the Anuradhapura kingdom from 277 to 304 BC. It is said that the king built Nachchaduwa Wewa to supply ample water to Anuradhapura and to protect the city from floods. King Datusena built a canal named Yodha Ela to bring water to Nachchaduwa Wewa from Kala Wewa.",
  },
  {
    imageUrl: image12,
    left: false,
    bottom: true,
    title: "Thanthirimale",
    description:
      "\tLocated approximately 80 kilometers outside of the town of Mannar and 40 kilometers northwest of Anuradhapura city, Thanthirimale is an old village in Sri Lanka’s Anuradhapura District that is known for its ancient temple: the Thanthirimale Raja Maha Vihara. This temple is considered an important and central part of the village, having been built around the 3rd century BC.\n\nAccording to Legend\n\n\tLegend has it that when the Jaya Sri Maha Bodhi (a fig tree that was grown from a branch of the ancient tree under which Lord Buddha attained Enlightenment) was brought to Sri Lanka from India as a sapling, it was kept for one night at Thanthirimale before continuing its journey to Anuradhapura. It is further believed that a branch from the sapling was taken and planted at Thanthirimale in commemoration, and that this therefore may be the first Sri Maha Bodhi to be planted in the country.\n\nThe Temple Today and the Best Time to Visit\n\n\tIn the 1960s, Buddhist monks reestablished the temple. Today, the ruins surrounding the temple can be explored, featuring two impressive stone statues and several stone ponds.\nIn order to best enjoy a visit to Thanthirimale, vacationers should aim to journey through between the months of July and September, and between December and February, for ideal weather conditions. The summer periods in the area between April and June can reach exceptionally high temperatures, and so it is recommended to avoid visiting during this time      ",
  },
  {
    imageUrl: IsinbassagalaImage,
    left: false,
    bottom: true,
    title: "Isinbassagala",
    description: "\tIsin Bassa Gala or Isinbassagala is an unknown place to the tourists but this off beat place can be as surprising as any other famous tourist spot. The Stupa and the temple are set in a perfect location and it offers a 360 degree view of the landscapes. Located at a distance of nearly 2 kilometres from the town of Medawachchiya, along the A9 highway, a Stupa perched on a hill can be seen.\n\n\tThe temple here was built by King Devanampiyatissa right after the doctrine of Buddhism was taken birth in the country. The Stupa and the temple are the  reconstructions of the original done in 20th century. A Naga Gala or a cobra carved stone and foot prints of Lord Buddha were found in the excavations. This site preserves the deities of two different times of the land. Nagas were worshipped before the advent of Buddhism in Sri Lanka. Along with these, a Bodhi tree and large statues of the king and Buddha are installed here",
  },
  {
    imageUrl: YapahuwaImage,
    left: false,
    bottom: true,
    title: "Yapahuwa",
    description:
      "\tYapahuwa is located a little way off the Kurunegala-Anuradhapura road, in the Wayamba province of Sri Lanka. Of all the ancient ruins in the country the Rock Fortress Complex of Yapahuwa is considered to be quite remarkable despite the fact that it isn't famous among most visitors. However, it is renowned as one of the best archeological site in the country. It is even rumored to be more significant than The Rock Fortress in Sigiriya.\n\nHistory Of Yapahuwa\n\n\tIn the early 13th century Yapahuwa was the capital of the country and it housed the Sacred Tooth Relic of the Buddha for 11 Years. King Bhuvanekabhu I, the son of the King Parakramabahu who at that time ruled Dambadeniya, was stationed at Yapahuwa in order to protect the Country from Invaders; built the palace and the temple. After the Fortress was abandoned monks converted it into a monastery and monks still reside among the ancient ruins. Even today signs of early defense mechanisms can still be seen among the ruins.\n\nStructural Significance\n\n\tOn top of the rock the remains of a stupa, a Bodhi tree, and a rock shelter/cave used by Buddhist monks is visible. A couple of caves are seen at the base of the rock, one of which is a Buddhist Shrine whereas another cave has some inscriptions on it. The rock fortress has a strong resemblance to the Sigiriya Rock Fortress.      ",
  },
  {
    imageUrl: ResWeheraImage,
    left: true,
    bottom: false,
    title: "Res wehera",
    description:
      "\tReswehera Rajamaha Vihara is an ancient temple which was built by the king Devanampiya Tissa situated in North Western Province in Sri Lanka. The temple is well known for its two Gautama Buddha statues, one carved in a rock and the other inside the Vihara.",
  },
  {
    imageUrl: imageb1,
    left: false,
    bottom: true,
    title: "Aukana Buddha Statue",
    description:
      "\tThe Avukana statue is a standing statue of the Buddha near Kekirawa in North Central Sri Lanka. The statue, which has a height of more than 14 m (46 ft.), depicts the Buddha with a hand raised in reassurance, a variation of the Abhaya mudra. The Avukana statue is one of the best examples of a standing statue built in Sri Lanka. It is now a popular tourist attraction. \n\tAukana Ancient Rock Temple is a sacred Buddhist temple region that has numerous worth-seeing sacred residues including Aukana Buddha, a massive Buddha statue carved out of a large single rock, towering over 40ft height, being the specimen of excellent ancient sculpture technology of the nation. Both local and foreign pilgrims and tourists visit this sacred tourist attractions in Sri Lanka for seeing this tallest ancient standing Buddha statue of the nation, carved out elaborately in 5th century BC with an interesting history behind. Here you explore both the ancient civilization and culture and the antique yet finest stone-art of Sri Lanka.\n\nTourist Attractions At & Near Aukana Buddhist Temple \n\n\tAukana Village is a peaceful area with lush vegetation and shades consisting of several small temples with stupas, statues, and sacred residues. Located near the Aukana Rajamha Viharaya temple, seen at the entrance, the Aukana Buddha statue is the greatest amusement that is going to takes you over. Inside a large ancient shrine, it is a standing statue, regarded as the tallest ancient statue of Budhha with approximately 12 meters height, made out of single stone without complete separation, but with a narrow layer of rock left at the rear side of the statue offering assurance. There is one more statue of considerable height nearby in an unfinished form telling a different story. You have Kala Wewa reservoir nearby.",
  },
  {
    imageUrl: KalawewaImage,
    left: false,
    bottom: true,
    title: "Kalawewa",
    description:
      "\tKala Wewa built by the King Datusena in 460 A.D, is a twin reservoir complex which has the capacity to store 123 million cubic meters of water. This reservoir complex has facilitated with a stone made spillway and three main sluices.",
  },
  {
    imageUrl: VijithapuraImage,
    left: true,
    bottom: false,
    title: "Vijithapura",
    description:
      "\tVijithapura Raja Maha Viharaya is believed to be built on the winning grounds of the last battle of King Dutugemunu against the Tamil Invader Elara.",
  },
  {
    imageUrl: DambullaImage,
    left: false,
    bottom: false,
    title: "Dambulla Cave Temple",
    description:
      "\tDambulla cave temple, also known as the Golden Temple of Dambulla, is a World Heritage Site in Sri Lanka, situated in the central part of the country. This site is situated 148 kilometres east of Colombo, 72 kilometres north of Kandy and 43 kilometres North of Matale.",
  },
  {
    imageUrl: image14,
    left: false,
    bottom: true,
    title: "Ritigala",
    description:
      "\tRitigala is an world famous ancient Buddhist monastery and mountain range in Sri Lanka . The ruins and rock inscriptions of the monastery date back to 1st century BC. It is located 43 km away from the ancient city of Anuradhapura and also 40Km from Dambulla. The interesting feature is Its height and the local weather patterns create a microclimate difference from that of the surrounding plains. Ritigala is home to a variety of rare species of plant and animal with immense of biodiversity which is now strictly protected.\n The half-day Ritigala hike excursion is quite unique experience any one can get. you can walk through a forest decorated with fauna and flora such as orchids, seated on the remains of ancient stone bathing-pools, bridges, courtyards and other architectural remains, while colourful birds and mischievous monkeys look at you from the treetops of the jungle. \n\n\t•Time of departure: Any time you can vist , But better early in the morning or evening. \n\t•Also can couple with Polonnaruwa or Mineriya elephant safari tour if required.  \n\t•Travel Time: 01 hr  \n\t•Tour Duration: 02 hrs Hiking",
  },
  // /------------------ need to edit here
  {
    imageUrl: SigiriyaImage,
    left: false,
    bottom: true,
    title: "Sigiriya",
    description:
      "\tSigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It is a site of historical and archaeological significance that is dominated by a massive column of granite approximately 180 m (590 ft.) high.\n\tAccording to the ancient Sri Lankan chronicle the Cūḷavaṃsa, this area was a large forest, then after storms and landslides it became a hill and was selected by King Kashyapa (AD 477–495) for his new capital. He built his palace on top of this rock and decorated its sides with colourful frescoes. On a small plateau about halfway up the side of this rock he built a gateway in the form of an enormous lion. The name of this place is derived from this structure; Siṃhagiri, the Lion Rock.\n\tThe capital and the royal palace were abandoned after the king's death. It was used as a Buddhist monastery until the 14th century. Sigiriya today is a UNESCO listed world heritage site. It is one of the best preserved examples of ancient urban planning.",
  },
  // /------------------ OK
];

export default attractions;




