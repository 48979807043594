import { TestimonialCard } from './TestimonialCard/TestimonialCard'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import './testimonials.css'
import Slider from 'react-slick';
import flag1 from '../../images/flags/national-flag-spain.png'
import flag2 from '../../images/flags/national-flag-canada.png'
import flag3 from '../../images/flags/national-flag-of-portugal.png'
import { Carousel, CarouselItem } from 'react-bootstrap';

const testimonials = [
    {
        message: "The place was lovely. A beautiful and spacious building, full of natural light, with wonderful views of paddy fields from our window. It is owned and run by the kindest family in Sri Lanka, which is saying a lot, because people here are extremely nice wherever you go.",
        author: "Julieta",
        flag: flag1
    },
    {
        message: "Heaven Upon Rice Fields exceeded our expectations - we couldn't believe how amazing this spot was for the price! The hosts are so lovely - caring, family oriented and happy to answer any questions about our trip. The rooms were beautiful with the most comfortable king bed we've ever slept on! Gorgeous white sheets and duvet and perfect pillows. Thank you so much!",
        author: "Jen",
        flag: flag2
    },
    {
        message: "We came from Portugal with no plans and booked our accommodations on the go. Finding this place was just amazing; an absolute gem of a relaxation in the busy area of Anuradhapura. The host are incredibly nice and helpful, the food was great, rooms were extra clean and comfy; all of this for very little money. Don’t miss this one out.!",
        author: "Jessica",
        flag: flag3
    },
]


export const Testimonials = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="testimonials-box">
            <div className="testimonials-title-box">
                <p>What Our Guests Say</p>
                <h1>Testimonials</h1>
            </div>
            <div className="testimonoals-msg-box">
                <Slider {...settings}>
                    {
                        testimonials.map(({ message, author, flag }, index) => {
                            return <TestimonialCard key={index} message={message} author={author} flag={flag} />
                        })
                    }
                </Slider>
                {/* <Carousel>
                    {
                         testimonials.map(({ message, author, flag }, index) => {
                            return <CarouselItem key={index}><TestimonialCard key={index} message={message} author={author} flag={flag} /></CarouselItem>
                        })
                    }
                </Carousel> */}
            </div>
        </div>
    )
}
