import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TripadvisorIcon from '../../images/slink/tripadvisor_bw.png'
import BookingIcon from '../../images/slink/booking.png'
import YouTubeIcon from '@mui/icons-material/YouTube';

import { IconButton } from '@mui/material';
import sociallink from '../../db/sociallink';

export const Footer = () => {
  return (
    <div className='footer'>
      <p className="copy" style={{ textAlign: 'center' }}>Heaven Upon Rice Fields © 2023 All Rights Reserved.</p>

      <div className='link-icons'>
        <IconButton href={sociallink.facebook} target="_blank" >
          <FacebookIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton href={sociallink.whatsapp} target="_blank">
          <WhatsAppIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton href={sociallink.instagram} target="_blank">
          <InstagramIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton href={sociallink.tripadvisor} target="_blank">
          <img src={TripadvisorIcon} alt="TripadvisorIcon" width={30}/>
        </IconButton>

        <IconButton href={sociallink.youtube} target="_blank">
          <YouTubeIcon style={{ color: 'white' }} />
        </IconButton>

        <IconButton href={sociallink.booking} target="_blank">
          <img src={BookingIcon} alt="TripadvisorIcon" width={20} style={{filter: 'grayscale(100%)'}}/>
        </IconButton>
      </div>

    </div>
  )
}
