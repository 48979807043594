import { Backdrop, Box, Button, CircularProgress, Modal, Stack, TextField, Typography } from '@mui/material'
import './contact.css'
import { useState } from 'react'
import axios from 'axios';
import target from '../../db/target';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import InstagramIcon from '@mui/icons-material/Instagram';
import InstagramIcon from '../../images/slink/instagram.png'
import TripadvisorIcon from '../../images/slink/Tripadvisor.png'
import BookingIcon from '../../images/slink/booking.png'
import YouTubeIcon from '@mui/icons-material/YouTube';

import { IconButton } from '@mui/material';
import sociallink from '../../db/sociallink';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 230, sm: 400 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const contactDetails = {
    title: "",
    description: `Every moment of your journey is important for us and that is exactly why we want to be sure you’ve enjoyed the stay at Heaven Upon Rice Fileds. Leave your feedback to help us be better for you or share your sunny memories and holiday experience.

    We appreciate and gladly welcome your opinion and comments! 
    
    +94 76 7253653
    heavenuponricefields@gmail.com
    
    3/3A,Nuwarawewa watta,Mihinthala Road, Jaffna Junction, Anuradhapura`,

}


const contactForm = {
    name: "",
    email: "",
    subject: "",
    message: ""
}

export const Contact = () => {

    const [data, setData] = useState(contactForm)
    const [open, setOpen] = useState(false);
    const [openOK, setOpenOK] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);

    const handleSubmit = (event) => {
        // console.log(data);
        event.preventDefault();
        if (data.email == '' || data.message == '' || data.name == '' || data.subject == '') {

            alert("Fill all the details")
        } else {

            setOpen(true)
        }
    }

    const handleChange = (event) => {



        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const handleClose = () => {
        // console.log('CLOSED');
        setOpen(false)
    }


    const contactHandler = () => {
        console.log(data);

        console.log("SENT");
        setOpen(false)
        setOpenLoading(true)

        axios.post(target + 'api/contact/send', {
            data
        })
            .then(res => {
                console.log(res);
                setOpenLoading(false)

                if(res.data.edata?.state !== undefined){
                    if(res.data.edata.state === 'OK'){
                        setOpenOK(true)                    
                    } else {
                        alert(res.data.edata.state)
                    }
                    
                }else {
                    alert(res.data.edata)
                }

            })
            .catch(error => {
                console.error(error);
                alert(error.message)
                setOpenLoading(false)
            })

    }


    return (
        <div className='contact-page'>
            <div className="contact-box">
                <Box className="contact-title-box" sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography variant='h4'> Contact Us</Typography>
                </Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} className="contact-details-inputs-box">

                    <Box className="contact-details-box" >
                        <h2 className="contact-d-title notranslate">Get in Touch</h2>
                        <p className="contact-d-description">Every moment of your journey is important for us and that is exactly why we want to be sure you’ve enjoyed the stay at Heaven Upon Rice Fileds. Leave your feedback to help us be better for you or share your sunny memories and holiday experience.</p>
                        <p className="contact-d-description-1">We appreciate and gladly welcome your opinion and comments! </p>
                        <div className='contact-details-box-ne'>
                            <p className="contact-d-description-number">+94 71 2653653 / +94 76 7253653</p>
                            <p className="contact-d-description-email">heavenuponricefields@gmail.com</p>
                        </div>
                        <p className="contact-d-description-address">5th Lane, Nuwarawewa watta, Mihinthala Road, Jaffna Junction, Anuradhapura</p>

                        <div>
                            <p className="contact-d-description-find">FIND US ON</p>
                            <div className='link-icons-find'>
                                <IconButton href={sociallink.facebook} target="_blank" >
                                    <FacebookIcon style={{ color: 'blue' }} />
                                </IconButton>

                                <IconButton href={sociallink.whatsapp} target="_blank">
                                    <WhatsAppIcon style={{ color: 'green' }} />
                                </IconButton>
                                
                                <IconButton href={sociallink.instagram} target="_blank">
                                    {/* <InstagramIcon style={{ color: 'red' }} /> */}
                                    <img src={InstagramIcon}  width={20} />
                                </IconButton>

                                <IconButton href={sociallink.tripadvisor} target="_blank">
                                    <img src={TripadvisorIcon}  width={25} />
                                </IconButton>

                                <IconButton href={sociallink.youtube} target="_blank">
                                    <YouTubeIcon style={{ color: 'red' }} />
                                </IconButton>

                                <IconButton href={sociallink.booking} target="_blank">
                                    <img src={BookingIcon}  width={20} />
                                </IconButton>

                            </div>
                        </div>
                    </Box>

                    <div className="contact-inputs-box notranslate">
                        <form onSubmit={handleSubmit} className='contact-form'>
                            <TextField name='name' label="Name" onChange={handleChange} />
                            <TextField name='email' label="Email" onChange={handleChange} />
                            <TextField name='subject' label="Subject" onChange={handleChange} />
                            <TextField name='message' label="Message" multiline rows={4} onChange={handleChange} />
                            <Button type='submit' variant="contained">Submit</Button>
                        </form>
                    </div>
                </Stack>
            </div>


            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" sx={{ marginBottom: '5 px', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}> Confirm your form </Typography>
                    <Typography id="modal-modal-description" sx={{ marginLeft: '15px', fontSize: { xs: '.825rem', sm: '1rem' } }} >
                        is the email correct? <span style={{ fontWeight: 'bold' }}>"{data.email}"</span>
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px', marginTop: '20px' }}>
                        <Button size='' onClick={handleClose} >Close</Button>
                        <Button size='small' variant='contained' onClick={contactHandler} >Confirm</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openOK}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" sx={{ marginBottom: '5 px', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>Your Form Sent Successfully</Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px', marginTop: '20px' }}>
                        {/* <Button size='' onClick={() => setOpenOK(false)} >Close</Button> */}
                        <Button size='small' variant='contained' onClick={() => {
                            setOpenOK(false)                            
                            window. location. reload()
                            }} >OK</Button>
                    </Box>
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            // onClick={setOpenLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    )
}
