

import IsurumuniyaImage from "../../../images/attraction2/Isurumuniya.png";
import Stargate from '../../../images/history/Stargate.png'
import ImageBox from './ImageBox'

const Isurumuniya = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Isurumuniya</div>

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        The Isurumuniya Viharaya is situated close to the Tisa Wewa. It is renowned for its stone carvings of which the most famous is one known as the “Isurumuniya Lovers”. The other carvings are of the Horseman, Elephant Pond and the Royal Family.The Temple was originally known as the Meghagiri Vihara and it is believed that the Vessagiri Vihara which is located close to it is the actual Isurumuniya Vihara
                    </div>
                </div>

                <div className='attraction-park-box'>
                    <h3>The Temple</h3>
                    <div className="attraction-park-discription">
                        The Isurumuniya Viharaya was constructed during the reign of Devanampiya Tissa who governed in Anuradhapura, the country’s capital. During his reign 500 high caste children were ordained, and Isurumuniya was built as a monastic complex to house all of them. Thereafter it was renovated by King Kasyapa (473 – 491 AD) who renamed it “Boupulvan Kasubgiri Radmaha Vehera” – a combination of his name along with his two daughters’. Connected to the Temple is a cave with a cliff above it. A small Dagoba was built on this cliff during a later period. At the picturesque entrance to Isurumuniya a rock can be seen either side of a crevice, and this rock seems to rise out of a pond.
                    </div>
                </div>

                <div className='attraction-park-box'>
                    <h3>The Isurumuniya Lovers</h3>
                    <div className="attraction-park-discription">
                        To the north of Isurumuniya lies the Ranmasu Uyana or Royal Pleasure Garden. This is a significant location as it is believed that Prince Saliya met Asokamala, his future bride, in this very garden all those centuries ago.
                        <br></br>
                        The sculpture known as the “Isurumuniya Lovers” is a Gupta style carving of the 6th Century. It shows a woman seated on a man’s lap, lifting a finger in what some people interpret as a manifestation of her coyness. A certain mystery surrounds the carving as no record exists of exactly whom the two images portray. Locals believe the couple depicted in the carving are that of Prince Saliya and Asokamala. Another legend has it that it is the Hindu god Shiva and goddess Parvati depicted in the famous carving.
                        <br></br>
                        It is possible that the figures represent Prince Saliya (son of King Dutugemunu) and Asokamala, the maiden of low caste he loved. According to historical reports he eventually gave up the throne for her.
                        <br></br>
                        Another legend has it that it is Shiva and Parvati depicted in the famous carving.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>A Sense of Romance</h3>
                    <div className="attraction-park-discription">
                        The carving of the “Isurumuniya Lovers” has sparked many legends over the centuries. But each one seems to have a happy ending and the legends live on. Step into this romantic setting and share this ancient love story as Anuradhapura tour takes you through this unforgettable experience.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Other Rock Carvings</h3>
                    <div className="attraction-park-discription">
                        At the picturesque entrance to Isurumuniya a rock can be seen on either side of a crevice, and this rock seems to rise out of a pond. Just above the water level are carvings of bathing Elephants. To the side of the pool is a flight of stairs. Another unique rock carving is found at the top. This time of a man seated in a regal position with the head of a horse behind him. This figure is believed to be of Parjanya, the God of rain. This has led to the belief that the Elephants in the carving below represent rain clouds. Therefore, archaeologists over the centuries have assumed that the King used this location to perform rituals to the God of rain.
                        <br></br>
                        The carving of the famous “Isurumuniya Lovers” has sparked many legends over the centuries. But each one seems to have a happy ending and the fables live on. It is truly a place where art takes on a different dimension.
                    </div>
                </div>
                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <h3 style={{marginBottom: '10px'}}>The Stargate at Ranmasu Uyana</h3>
                    <ImageBox imageUrl={Stargate} />
                    <div style={{marginTop: '10px'}} className="attraction-park-discription">
                        Sandwiched between the Thissa Wewa reservoir and the Isurumuniya Rock Temple, RanmasuUyana of Anuradhapura, a forty acre pleasure garden of the ancient Sri Lankan royals is a unique creation.
                        The Ranmasu Uyana was mainly known for its rock sculpted bathing ponds with a sophisticated hydraulics mechanism. A subtle use of pressure difference enables the pumping of water from the nearby Tissa Wewa to the swimming pools of the garden. The pools themselves display a clever use of technology and craftsmanship, in constructing a flawless luxury swimming facility nearly three thousand years ago.
                        <br></br><br></br>
                        However the true wonder of RanmasuUyana is found among the rock boulders and caves away from the pools. Carved on a stone wall is a strange map like chart known to many as the ‘stargate’. A stargate is believed to be a gateway between the earth and the outer space, through which humans allegedly roamed the universe communicating and engaging with other intelligent beings in the universe. This chart is believed to be the secret code to accessing the stargate. Facing the stargate marking are four seats, which implies that it was a chart, which could be used or manipulated by four individuals.
                        The discovery of the stargate marking had created waves amidst archaeologists and pre-historic investigators, some drawing parallels between the stargate charts found in Abu Ghurab in Egypt and ‘the gate way to the gods’ in Peru. The bizarre coincidence of all the three marks been found in the vicinity of a water way, with sophisticated engineering methods, had given rise to various theories.
                        <br></br>
                        <br></br>
                        The most favourite is the claim that these stargates were used by extraterrestrials that were gold mining in Earth. Their theory is built up on the fact that in 2400 BC the stargate in Abu Ghurab Egypt was surrounded by water while” La Puerta de HayuMarka.” Or “The Gateway to The Gods” in Peru too is built closer Lake Titicaca, with underground water tunnels and chambers beneath the pyramids.
                        The advocates of the ‘processing gold from the waters of Earth’ theory believes that these water channels, chambers, reservoirs and filters found near each stargate to have been a part of a sophisticated mechanism developed by extraterrestrials thousands of years ago.
                        If the theory is held to be true, RanmasuUyana had once been a ‘gold processing center of extraterrestrials’, which arrived on earth through stargates.
                        <br></br><br></br>
                        Yet archaeologists are eager to discard these theories as pure imagination. According to the earliest archaeologists and historians the charts could have been a descriptive chart of the universe according to Buddhism or a simple map of the earth. At later stages the historians have interpreted them as descriptions of animal evolution or as the way Tantric Buddhist monks perceived the world. It is believed that they used them for meditation purposes as well.
                        Present day archaeologist abhor the thought of comparing Egyptian civilization with the Sri Lankan civilization, insisting that ours is a unique island civilization pure of Egyptian or Babylonian influence.
                        Whatever their initial use may have been, the interpretations provided for these markings varies from a secret key to space travelling to the floor plan of Sigiriya, a rock garden found closer to Anuradhapura. Yet the only fact about these charts is that despite various claims and arguments, we are nowhere near to understanding them or knowing their sources.
                    </div>
                </div>

                <ImageBox imageUrl={IsurumuniyaImage} />
            </div>
        </div>
    )
}

export default Isurumuniya
