import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Availability } from "./componets/Availability/Availability";
import { Footer } from "./componets/Footer/Footer";
import { Header } from "./componets/Header/Header";
import { Home } from "./pages/Home/Home";
import { Rooms } from "./pages/Rooms/Rooms";
import {
  Button,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import { Contact } from "./pages/Contact/Contact";
import { Gallery } from "./pages/Gallery/Gallery";
import { useReducer, useState } from "react";
import dayjs from "dayjs";
import Visit from "./pages/Visit/Visit";
import Attraction from "./pages/Attraction/Attraction";

function App() {


  
  const [loading, setLoading] = useState(true)
  const [showOffers, setShowOffers] = useState(true)


  const pageReducer = (state, action) => {
    // console.log(action);

    if (action.type === "change") {
      setPage("");
      return {
        page: action.page,
        data: action.data,
      };
    } else if (action.type === "navigate") {
      return {
        page: action.page,
        data: action.data,
      };
    }
    return state;
  };

  const [pageSelected, setPage] = useState("");
  const [page, pageDispatch] = useReducer(pageReducer, {
    page: "home",
    data: {
      state: "all",
      group: "0",
      check: {
        in: new dayjs(),
        out: new dayjs().add(1, "day"),
        nights: 0,
        adults: 1,
        children: 0,
      },
    },
  });

  // console.log(page);
  const theme = useTheme();

  const mq_xs = useMediaQuery(theme.breakpoints.only("xs"));
  const mq_sm = useMediaQuery(theme.breakpoints.only("sm"));
  const mq_md = useMediaQuery(theme.breakpoints.only("md"));
  const mq_lg = useMediaQuery(theme.breakpoints.only("lg"));
  const mq_xl = useMediaQuery(theme.breakpoints.only("xl"));

  // console.log(mq_xs, mq_sm, mq_md, mq_lg, mq_xl);

  return (
    <BrowserRouter>
      <div>
        {pageSelected}
        <Header pageData={page} dispatch={pageDispatch} />
      </div>

      <Routes>
        <Route index element={<Home dispatch={pageDispatch} status={{loading, setLoading, showOffers, setShowOffers}}/>} />
        <Route
          path="/rooms"
          element={<Rooms data={page.data} dispatch={pageDispatch} />}
        />
        <Route path="/attraction" element={<Attraction />} />
        <Route path="/history" element={<Visit />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
