import { Button, Typography } from '@mui/material'
import './tours.css'
import maskImage from '../../images/travel/background2.png'
import travelImage from '../../images/travel/srilankain.jpg'
import { forwardRef } from 'react'

export const Tours = forwardRef((props, ref) => {

  return <div ref={ref} className='toure-parallax'>
    <div className="toure-section">
      <div className='toure'>
        <div className="toure-msg-box">
          <div className="toure-title">
            <Typography className='toure-title-title' sx={{ fontSize: { xs: '38px', md: '48px' } }}>Time To Travel</Typography>
            <Typography className='toure-title-discription' sx={{ fontSize: { xs: '24px', md: '36px' } }}>Enjoy In the Wild</Typography>
            <Typography className='toure-discription' sx={{ fontSize: { xs: '14px', md: '18px', fontWeight: '100' } }}>Travel Agents with local connections, knowledge and expertise can make your holiday more canker free and fun filled. They can organise our holiday getting you the best deals and providing you with the hotels, transportation and other assistance needed to make your holiday an unforgettable one.</Typography>
            <Button variant='contained'>Visit the web site</Button>
          </div>
        </div>
      </div>
    </div>
  </div>


  // return (
  //   <div className="toure-section">
  //     <div className='toure'>
  //       <div className="toure-msg-box">
  //         <div className="toure-title">
  //           <p className='toure-title-title'>Time To Travel</p>
  //           <p className='toure-title-discription'>Enjoy In the Wild</p>
  //           <p className='toure-discription'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat asperiores dolorem totam rerum veritatis, eaque voluptatum, pariatur ut tempore doloribus a molestiae temporibus minima consectetur quae! Unde beatae sint laboriosam.</p>
  //           <Button>See More</Button>
  //         </div>
  //       </div>
  //       <div className="travel-img-box">
  //         <img className='toure-img' src={travelImage} alt="" />
  //         <img className='toure-mask' src={maskImage} />
  //       </div>
  //     </div>
  //   </div>
  // ))
})
