import './packages.css'
import { Box } from '@mui/material'

export const Packages = ({scrolToSection, elements}) => {



  return (
    <Box className='packages-section' sx={{ display: { xs: 'none', md: 'flex'} }} >
        <div className="packages-box">
            <div className="package-1" onClick={() => scrolToSection(elements.tours)}>
              {/* <img src={offerTag} alt="" srcSet="" className='img'/> */}
                {/* packages */}
                <h2>Travel in Sri Lanka</h2>
            </div>
            <div className="package" onClick={() => scrolToSection(elements.rooms)}>
                {/* packages */}
                <h2>Rooms & Suites</h2>
            </div>
        </div>
    </Box>
  )
}
