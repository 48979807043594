import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material'
import './offers.css'
import { useEffect, useState } from 'react';
import target from '../../db/target';
import axios from 'axios';

function IsImageOk(img) {
    // During the onload event, IE correctly identifies any images that
    // weren’t downloaded as not complete. Others should too. Gecko-based
    // browsers act like NS4 in that they report this incorrectly.
    if (!img.complete) {
        return false;
    }

    // However, they do have two very useful properties: naturalWidth and
    // naturalHeight. These give the true size of the image. If it failed
    // to load, either of these should be zero.
    if (img.naturalWidth === 0) {
        return false;
    }

    // No other way of checking: assume it’s ok.
    return true;
}


const ImageSlice = ({ url, index }) => {

    const image = document.getElementById(`id-${index}`)

    // console.log(`id-${index}`, image?.complete);

    // setTimeout(() => {
    //     console.log(`id-${index}`, IsImageOk(image));
    // }, 500)

    return <div className="mySlides fade">
        {/* <div className="numbertext">2 / 3</div> */}
        <img className='imgshow' id={`id-${index}`} src={url} width={'100%'}  />
        {/* <div className="text">Caption Two</div> */}
    </div>
}



const Offers = ({ setShowOffers }) => {

    const topup = document.getElementById('offers-topup')
    if (topup?.style.visibility !== undefined) {
        topup.style.visibility = 'hidden'
    }

    const showOffere = () => {


        let length = document.getElementsByClassName("mySlides").length;
        // const topup = document.getElementById('offers-topup')
        // console.log( topup.style.visibility);
        

        let show = false


        for (let i = 0; i < length; i++) {
            const image = document.getElementById(`id-${i}`)
            // console.log(`id-${i}`, image?.complete);

            if (image?.complete === undefined || image?.complete === false) {
                // setTimeout(() => {
                //     // showOffere(length)
                //     console.log('reload...');
                // }, 1000);
                show = false
            } else {

                show = true
                // console.log('OK-images-loaded');

            }

        }

        
        // console.log('style', topup?.style.visibility);

        setTimeout(() => {
            if (show) {
                if (topup?.style.visibility !== undefined) {
                    topup.style.visibility = 'visible'
                }
            }
            // console.log('style', topup?.style.visibility);
        }, 500);

    }


    const [loadingImages, setLoadingImages] = useState([])
    const [index, setIndex] = useState(0)
    // const [showingImages, setShowingImages] = useState([])




    const endPoint = target + 'images/rooms/'

    useEffect(() => {

       

        axios.get(target + 'api/client/offers/all')
            .then(res => {


                // console.log(res.data);

                const data = res.data.filter(img => {
                    return img.state === 'loading'
                })

                // if(!data[0]?.name) setShowOffers(false) 

                setLoadingImages(data)

                // setShowingImages(res.data.filter(img => {
                //     return img.state === 'showing'
                // }))

            })
            .catch(error => {
                console.log(error)
            })


        showSlides();
    }, [])

    let slideIndex = 0;

    function showSlides() {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        // console.log(slides);
        if (slides.length > 0) {
            let dots = document.getElementsByClassName("dot");
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            slideIndex++;
            if (slideIndex > slides.length) { slideIndex = 1 }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active1", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " active1";
        }
        setTimeout(() => {
            showSlides()
        }, 3000); // Change image every 2 seconds
    }

    showOffere()

    return (
        <div className='offers-topup' id='offers-topup'>

            <div className='offers-topup-box'>


                <div className='offers-topup-items'>
                    <div className="slideshow-container">

                        <div className='offers-topup-close'>
                            <IconButton onClick={() => setShowOffers(false)} >
                                <CloseIcon style={{ color: 'red' }} />
                            </IconButton>
                        </div>

                        {
                            loadingImages.map((img, index) => {
                                // console.log('try');
                                return <ImageSlice key={index} index={index} url={endPoint + img.name} />
                            })
                        }

                        <div className='slidershow-dot'>
                            {/* <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span> */}
                            {
                                loadingImages.map((img, index) => {
                                    return <span key={index} className="dot"></span>
                                })
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Offers
