
import Koneswaram from '../../../images/history/Japna.png'
import ImageBox from './ImageBox'

const Jaffna = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Jaffna</div>

                <div className='attraction-park-box'>
                    <h3>Delft Island</h3>
                    <div className="attraction-park-discription">
                        Although locally known as Neduntivu, Delft Island is still commonly referred to by the name it inherited from the Dutch in
                        colonial Jaffna. Vast, yet largely uninhabited, Delft Island is known for wild horses that roam its coastal plains. Explored best
                        by tuk-tuk, Delft Island also features remnants of a bygone era such as an ancient Baobab tree, as well as a dilapidated
                        colonial fort constructed mainly out of coral – much like most of the architecture present on the island.
                        At equal distance between Sri Lanka and India, Delft Island can be reached via a daily ferry that leaves the Kurikkaduwan Jetty.
                        The Sri Lanka Navy operates one round-trip service a day – leaving to Delft at 9 AM and returning to Kurikkaduwan at 2:30
                        PM. Although these ferries are able to hold a maximum of 100 passengers, preference will be given to Delft residents.
                        Schedules may also change, therefore feel free to speak to our Reception should you require any further information
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Point Pedro</h3>
                    <div className="attraction-park-discription">
                        At the very top of our teardrop isle, Sakkotai Cape rests as the northernmost point of Sri Lanka in the neighborhood of Point
                        Pedro. As you drive towards the coast from the Point Pedro town, you are first greeted with a directional signpost that
                        includes distances to various countries. To the west, the Point Pedro Lighthouse stands in the shadow of a
                        telecommunications tower, while to the east; a concrete flag of Sri Lanka sits at the edge of the shore to indicate your arrival
                        at Sakkotai Cape.<br></br>
                        An hour away from Jetwing Jaffna, Sakkotai Cape rests on a rocky coast at the perimeter of Point Pedro. A drive along the
                        shallow shoreline will allow you to skirt the northern edge of Sri Lanka, while your phone also picks up cellular signals from
                        India. On a clear day, you may even catch a glance of the India’s southern coastline in the distance.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Nagadeepa</h3>
                    <div className="attraction-park-discription">
                        Arguably one of the most visited of Jaffna’s islands, Nainativu, or Nagadeepa as it is referred to in Sinhala, holds an important
                        place in Buddhist and Hindu histories. For the former, the Nagadeepa Purana Viharaya is worshipped as one of Sri Lanka’s
                        holiest Buddhist sites where the Buddha was said to have once called upon two Naga kings in disagreement with each other.
                        And for the latter, the Nagapooshani Amman Kovil, which is referred to in several ancient chronicles as one of 64 Shakthi
                        Peethams located across South Asia – shrines dedicated to Shakthi, the Hindu goddess of power.<br></br>
                        From Jaffna, travellers to Nainativu must first hop across two islands on a journey to the Kurikkaduwan Jetty, located
                        approximately one hour away. From here, you will embark on a crowded 15-minute ferry to either the Nagadeepa Purana
                        Viharaya, or the Nagapooshani Amman Kovil. You may even choose to enter Nainativu from one, then walk or take a short
                        tuk-tuk ride to the other when leaving, in order to experience both journeys in one go.
                    </div>
                </div>
                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <h3>Keerimalai Beach</h3>
                    <div className="attraction-park-discription">
                        Keerimalai translates to ‘mongoose hill’ in the regional vernacular of Tamil. This refers to the local legend of a sage cursed
                        with a face likened to a mongoose, who was later cured upon immersing themselves in the healing waters of the Keerimalai
                        Sacred Water Spring. Today, the spring remains popular among throngs of local men and boys who splash about in a
                        picturesque ancient pond overlooking the sea. The bathing area for women is separate, and can be found behind the main
                        pond structure.<br></br>
                        Just next door, is also the Keerimalai Naguleswaram Kovil – one of Sri Lanka’s sacred Pancha Ishwarams, or shrines dedicated
                        to the Hindu god, Shiva, who is worshipped as the destroyer of evil. Dating back over thousands of years, it is said that the
                        same cursed sage established this kovil in gratitude of the adjacent pond’s healing powers.
                        Keerimalai sits at the top edge of Sri Lanka and can easily be coupled with a trip to Dambakola Patuna in one journey. The
                        sacred water spring and Naguleswaram Kovil are also within walking distance of each other, giving you ample time to explore
                        both attractions in all their glory.
                    </div>
                </div>
                
                <ImageBox imageUrl={Koneswaram} />
            </div>
        </div>
    )
}

export default Jaffna
