import { Box } from '@mui/material'
import { RoomGCard } from './RoomGCard/RoomGCard'
import './roomgroups.css'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'


const roomGroupsCard = [
  {
    title: "Deluxe Double Room with Balcony & Rice Paddy View",
    group: 0,
    imgUrl: 'roomg1.jpg',
  },
  {
    title: "Deluxe Double Room With Balcony",
    group: 1,
    imgUrl: 'roomg2.jpg',
  },
  {
    title: "Deluxe Double Room With Patio & Rice Paddy View",
    group: 2,    
    imgUrl: 'roomg3.jpg',
  },
]


export const RoomGroups = ({ dispatch }) => {


  return (
    <Box className='room-groups' sx={{padding: {xs: '40px 20px', md: '40px 20px'}}}>


      <div className="room-g-box">

        {roomGroupsCard.map(({ title, group, imgUrl }, index) => {
          return <Box onClick={() => {
            console.log('Group: ', group);
            // navigate('/rooms', {replace:true})
            dispatch({
              type: 'change',
              page: 'rooms',
              data: {
                state: 'group',
                group,
                check: {
                  in: new dayjs(),
                  out: new dayjs().add(1, 'day'),
                  adults: 1,
                  children: 0,
                }
              }
            })
          }} key={index} ><RoomGCard title={title} index={index} imgUrl={imgUrl} group={group} dispatch={dispatch} /></Box>
        })}

      </div>
    </Box>
  )
}
