
import MahakanadarawaImage from '../../../images/history/Mahakanadarawa.png'
import KaludiyapokunaImage from '../../../images/history/Kaludiyapokuna.png'
import Mihintale1 from '../../../images/history/Mihintale1.png'
import Mihintale2 from '../../../images/history/Mihintale2.jpg'
import ImageBox from './ImageBox'

const Mihintale = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Mihintale</div>

                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <div className="attraction-park-discription">
                        Mihintale is located only 12 km away from the famous Anuradhapura city as the crow flies but along the main road the distance to travel is 18 km. From Kandy it is 131 km along A9 Main Road. Travel time can vary between 2.30 to 4.00 hours depending on the traffic condition and the mode of transport ( car or route bus).
                        <br></br>
                        <br></br>
                        The sacred forest mountain Missaka Pabbatha or Mihintale Rock where King Devanampiya Tissa met Arhat Mahinda and his companions is situated in Mihintale. This sacred area is now called as Mihintale Temple. Prior to that the Lord Gautama Buddha had visited this place on his Third visit which was called as Missaka Pabbatha then. Being one of the sixteen places (Solosmasthana) the Lord Buddha had visited in Sri Lanka, this is regarded as one of the most important sacred places by the Buddhists in Sri Lanka.
                        <br></br>
                        <br></br>
                        The area of Mihintale surrounds with a jungle and there are rock boulders scattered around the mountainous area. There are many caves which were earlier used by monks for meditation in a quite surrounding away from the main city of Anuradhapura.
                        <br></br>
                        <br></br>
                        There is abundance of ancient stone architecture surrounding the many Stupas, monastic complexes and other sacred places. Asoka , the Emperor of India who became victorious after fighting a long war was disillusioned and sought Buddha's refuge to heal up his inner self. Becoming an ardent Buddhist, he let his son and the daughter be ordained as a Bhikku and a Bhikkuni (Buddhist priest and a nun named Mahinda and Sangamitta) whom later became Arhats. Arhath Mahinda and his companions came to Sri Lanka as a delegation sent by Arhat Moggali Putta Tissa with the patronage of Emperor Asoka on the 236 th year of the Buddha parinirvana. Sinhalese architecture, paintings and sculpture were a result of the Buddhism which nourished these throughout the centuries that followed. Mihintale architectural feats are mainly visible in Kantaka Cetiya Vahalkada, Alms Hall, Ancient Stairway, Kalu Diya pokuna and at ancient Hospital.
                    </div>
                </div>


                <ImageBox imageUrl={Mihintale1} />
                <br></br>
                <br></br>
                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <div className="attraction-park-discription">
                        The Mihintale Temple Complex spreads over three vegetated hills in Mihintale contributed by several monuments and attractions of historical and religious significance. The architectural brilliance can be observed in its flights of the stairway, Kanthaka chetiya Vahalkada, Alms Hall, ancient hospital, and Kalu Diya pokuna. There are a total of 1840 stone steps that cover the majority of the trail. Alms House Hall has an area of 114 x 77 with the ruins of stone pillars visible even now. The remains of the stone water supply are another skill of the architects. With a height of 40 feet and a circumference of 425 feet, the Kantaka Chetiya is believed to be one of the earliest Dagbas built in Sri Lanka.
                    </div>
                </div>
                <ImageBox imageUrl={Mihintale2} />

                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0', marginTop: '30px', }}>
                    <h3>Black Water Pool – Mihintale (Kaludiyapokuna)</h3>
                    <div className="attraction-park-discription">
                        Mihintale Kaludiya Pokuna is a monastic complex surrounding a water pond of 200 feet in length and 70 feet in width. It is situated away from the main complex along the main road proceeding about 500 mts.This is an artificial pond where the water is collected from the surroundings during the rainy season. The name Kalu Diya Pokuna or the Black Water Pond is said to be derived from the fact that the darkish colour of the reflections of the rock boulders and the surrounding trees appearing in the water.It is thought that the monks had mainly used this complex for meditation purposes. There is an inscription engraved which has Brahmi scripts belonging to B.C era.
                    </div>
                </div>

                <ImageBox imageUrl={KaludiyapokunaImage} />

                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0', marginTop: '30px', borderTop: '1px solid rgb(229, 229, 229)', paddingTop: '30px' }}>
                    <h3>Mahakanadarawa lake</h3>
                    <div className="attraction-park-discription">
                        Mahakanadarawa Wewa is a large reservoir built during the Anuradhapura kingdom by damming the Kanadara Oya, which is the main tributary of the Malwatu Oya. This reservoir has been called Kana Wapi and Kanadiyadora in the ancient to be built by King Mahasena (276-303 CE). It is reported that king Sena II (853-887 CE) had built a weir at a place called Kattaththa and diverted water to Kanadara Oya through a 12-kilometre canal to increase the supply of water to Mahakanadarawa Wewa.
                        <br></br><br></br>
                        The embankment of the tank is 2.8 km long and covers 34,000 acres (13.76 sq. km) when full. The total water holding capacity is 36 250 acre-feet (44,713,717 cubic meters). Unfortunately, the Anuradhapura — Trincomalee road is built across the tank, bisecting it into two.
                    </div>
                </div>

                <ImageBox imageUrl={MahakanadarawaImage} />
            </div>
        </div>
    )
}

export default Mihintale

