import { Box } from '@mui/material'
import React from 'react'
import ImageBoxCard from './ImageBoxCard'
import WilpattuImage from "../../images/history/Wilpattu.png";
import Japna from '../../images/history/Japna.png'
import Koneswaram from '../../images/history/Koneswaram.jpg'

const TourCard = ({ title, decs, plans }) => {

    return <div className='tour-card-box'>
        <h3>{title}</h3>
        <p className='tour-card-decs'>{decs}</p>

        <div className='tour-card-list'>{
            plans.map((item, index) => {
                return <div key={index} className='tour-card-list-item-box'>
                    <div className='tour-card-list-item'>
                        <p >{item}</p>
                    </div>
                </div>
            })
        }
        </div>

    </div>
}

const OneTourCard = () => {

    const onedata = [
        {
            title: 'Anuradhapura',
            decs: 'Anuradhapura is one of the ancient cities in Sri Lanka, was earlier the capital of the Island,& now named as a world heritage site by UNESCO. It is very famous among Buddhists pilgrims.',
            plans: ['Ruwanwelimahaseya', 'Jayasrimahabodiya', 'Lovamahapaya', 'Thuparamaya', 'Abayagiridagoba', 'Jethavana Dagob', 'Lankaramaya dagoba', 'Mirisawetiya dagoba', 'Wessagiriya', 'Isurumuniya', 'Tissa wewa']
        },
        {
            title: 'Mihinthale',
            decs: 'The place where Buddhism was born in Sri Lanka marking it a sacred site for all Buddhists',
            plans: ['Black Water Pool', 'Kanadarawa Lake', 'Nachchaduwa Lake']
        },
        {
            title: 'Wilpattu Safari',
            decs: 'The oldest and the biggest Sri Lankan national park, is a contender as the most beautiful national park in Sri Lanka – and that’s quite a claim.',
            plans: ['Wilpattu National Park']
        },
        {
            title: 'Dambulla',
            decs: 'Dambulla Cave Temple, is an ancient temple region situated in the central part of Sri Lanka, where plenty of time-old caves, rock temples, statues, paintings, and murals are the main attractions for the guests. Listed in UNESCO World Heritage list.',
            plans: ['Yapahuwa', 'Reswehera', 'Aukana', 'Kalawewa', 'Vijithapura', 'Dambulla']
        },
        {
            title: 'Sigiriya',
            decs: 'Sigiriya is one of the most valuable historical monuments of Sri Lanka. Referred by locals as the Eighth Wonder of the World, this ancient palace and fortress complex has significant archaeological importance and attracts thousands of tourists annually.',
            plans: ['Ritigala', 'Sigiriya', 'Pidurangala', 'Minneriya National Park', 'Kaudulla National Park']
        },
        {
            title: 'Polonnaruwa',
            decs: 'Polonnaruwa was the country’s capital from the 11th– 13th Century. One of the best preserved historic cities in the world & UNESCO World Heritage Site.',
            plans: ['Polonnaruwa']
        },
    ]

    return <Box className="tour-packages-box">

        <h2>One Day Tour Packages</h2>

        <div className='tour-packages-box-dec'>
            <ImageBoxCard imageUrl={WilpattuImage} />

            <div>
                {
                    onedata.map(item => {
                        return <TourCard key={item.title} title={item.title} decs={item.decs} plans={item.plans} />
                    })
                }
            </div>

        </div>

        <div className='tour-packages-box-round'></div>

    </Box>
}

const TwoTourCard = () => {

    const twoday = {
        title: 'Trincomalee',
        decs: 'Trincomalee is a port city on the east coast of Sri Lanka. The city is located on a peninsula, which divides the inner and outer harbours. It is one of the main where Tamil is spoken at large scale.',
        plans: ['Trincomalee']
    }

    return <Box className="tour-packages-box">

        <h2>Two Day Tour Packages</h2>

        <div className='tour-packages-box-dec'>
            <ImageBoxCard imageUrl={Koneswaram} />
            {/* <h3 className='tour-packages-box-dec-text'>Explore the ancient city of Anuradhapura, where towering stupas and intricate carvings narrate tales of bygone eras. Witness the sacred Bodhi Tree, a symbol of enlightenment, and delve into the rich history of this UNESCO World Heritage Site.</h3> */}
            <div>
                <TourCard title={twoday.title} decs={twoday.decs} plans={twoday.plans} />
            </div>

        </div>



        <div className='tour-packages-box-round'></div>

    </Box>
}

const FourTourCard = () => {

    const fourdata = [
        {
            title: 'Jaffna',
            decs: "After the end of the civil war, more and more guests are attracted to Jaffna Peninsula, the heartland of Sri Lanka's Tamil culture. The city of Jaffna stands as Sri Lanka's 12th largest city.",
            plans: ['Thanthirimala', 'Mannar', 'Jaffna', 'Isinbassagala']
        },
    ]

    return <Box className="tour-packages-box">

        <h2>Four Day Tour Packages</h2>

        <div className='tour-packages-box-dec'>
            <ImageBoxCard imageUrl={Japna} />
            {/* <h3 className='tour-packages-box-dec-text'>Explore the ancient city of Anuradhapura, where towering stupas and intricate carvings narrate tales of bygone eras. Witness the sacred Bodhi Tree, a symbol of enlightenment, and delve into the rich history of this UNESCO World Heritage Site.</h3> */}

            <div>
                {
                    fourdata.map(item => {
                        return <TourCard key={item.title} title={item.title} decs={item.decs} plans={item.plans} />
                    })
                }
            </div>
        </div>

        <div className='tour-packages-box-round'></div>

    </Box>
}

const TourPackages = () => {
    return (
        <div className='tour-packages-main'>
            <OneTourCard />
            <hr />
            <TwoTourCard />
            <hr />
            <FourTourCard />
        </div>
    )
}

export default TourPackages
