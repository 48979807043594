import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';

const btnStyle = {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100px'
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 230, md: 300 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SelectModal = ({ title = 'SELECT', handler, available }) => {


    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const handleOpen = () => {

        if (available) {

            setOpenAlert(true)

            setTimeout(() => {
                handleCloseAlert()
            }, 1000);

            handler()
        }
        else setOpen(true)

    };
    const handleClose = () => setOpen(false);
    const handleCloseAlert = () => setOpenAlert(false);

    return <div className='payment-page-book-btn' >
        <Button variant='contained' size='small' style={btnStyle} onClick={handleOpen} >{title}</Button>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <InfoIcon color='primary' />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}> First, check the availability </Typography>
                </Box>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>First, check the availability to select a room </Typography> */}
                <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                    <Button size='small' onClick={handleClose} >OK</Button>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={openAlert}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <InfoIcon color='primary' />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}> This was added to the cart</Typography>
                </Box>
            </Box>
        </Modal>
    </div >
}

export default SelectModal
