import { Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
// import CheckOut from 'https://test-bankofceylon.mtf.gateway.mastercard.com/static/checkout/checkout.min.js'
import './style.css'
import ShowPayment from './ShowPayment'



const CCart = ({ cart }) => {
    // console.log(cart);
    return <>
        <Typography variant='h6'> Room Details </Typography>

        <div className='payment-room'>
            {/* <div className='payment-info-cc'>
                {
                    cart.map((item, index) => <Typography key={index} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Room {index + 1}: </Typography>)
                }
            </div>
            <div className='payment-info-cc'> */}
            {
                cart.map((item, index) => {
                    return <div key={index} className='payment-room-box'>
                        <div className='payment-info-cc'>
                            <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Room {index + 1}: </Typography>
                        </div>
                        <div className='payment-info-cc'>
                            <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{item.packageTitle}</Typography>
                            <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{item.title}</Typography>
                        </div>
                    </div>
                })
            }
        </div>
    </>
}
const CInfo = ({ cinfo }) => {

    return <>
        <Typography variant='h6'> Customer Info </Typography>

        <div className='payment-info'>
            <div className='payment-info-c'>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Name: </Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Email: </Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Phone No: </Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Country: </Typography>
                {cinfo.passport ? <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Passport No: </Typography> : ''}
                {cinfo.message ? <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Message: </Typography> : ''}

            </div>
            <div className='payment-info-c'>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.title + '. ' + cinfo.fname + ' ' + cinfo.lname}</Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.email}</Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.number}</Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.country}</Typography>
                {cinfo.passport ? <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.passport}</Typography> : ''}
                {cinfo.message ? <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>{cinfo.message}</Typography> : ''}
            </div>
        </div>
    </>
}




class Payment extends React.Component {

    // console.log(w);
    // w.appendChild(totalPrice.totalObj)

    // console.log(payMethod, totalPrice, cinfo);

    // const [loading, setLoading] = useState(false)
    // const [sessionID, setSessionID] = useState('')

    // function getSessionID() {

    //     setLoading(true)

    //     axios.post('api/client/payment/session-id', pay)
    //         .then(res => {
    //             console.log(res.data);
    //             setSessionID(res.data['session.id'])
    //             window.parent.config(res.data['session.id'])
    //             // window.parent.run()

    //             setLoading(false)
    //         })
    //         .catch(err => console.log(err))
    // }

    // useEffect(() => {
    //     // getSessionID()
    // }, [])


    constructor(props) {
        super(props)

        window.scrollTo({
            top: 0
        })
        // console.log('Hee', props);
    }

    componentDidMount() {
        // console.log('Finished', this.props.discouttax);
        // console.log(this.props.totalPrice.totalObj);

        const w = document.getElementById('set-total-window')
        w.appendChild(this.props.totalPrice.totalObj)
    }

    render() {





        // const data = this.props




        // console.log('hi');

        // console.log(this.props.check);

        return (
            // <div className='checkout-payment-page' >
            //     {
            //         loading ? <CircularProgress color="success" /> : ''
            //     }
            //     <div className='hco-embedded' id='hco-embedded'></div>
            // </div>

            <div className='checkout-out'>

                <CCart cart={this.props.cartData.cart} />
                <hr style={{ marginBottom: '20px' }} />

                <CInfo cinfo={this.props.cinfo} />
                <hr style={{ marginBottom: '20px' }} />

                <Typography variant='h6'> Total </Typography>

                <div className="checkout-payment-page " id='set-total-window'>

                </div>



                <ShowPayment data={this.props} />




                {/* <CheckBookCpt/> */}


                {/* <BookBtn data={this.props}/> */}
            </div>

        )
    }
}

export default Payment
