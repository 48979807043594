import './roomgcard.css'
import roomGImg1 from '../../../images/roomgroup/roomg1.jpg'
import roomGImg2 from '../../../images/roomgroup/roomg2.jpg'
import roomGImg3 from '../../../images/roomgroup/roomg3.jpg'
import { Button } from '@mui/material'

export const RoomGCard = ({index, title, imgUrl, group, dispatch }) => {

  let roomImg;

  if(index === 0) roomImg = roomGImg1
  else if(index === 1) roomImg = roomGImg2
  else if(index === 2) roomImg = roomGImg3

  return (
    <div className="room-g-card">
      <img className='group-img' src={roomImg} alt="" />
      <div className="roomgroup-details-box">
        <h3 className="roomgroup-header">{title}</h3>
        <div className="roomgroup-price-box">
          {/* <p className="roomgroup-price">Starting From <span>US ${price}</span></p> */}
          <Button onClick={() => {
            
            console.log('Group BTN: ', group);
            dispatch({
              type: 'change',
              page: 'rooms',
              data: {
                state: 'group',
                group
              }
            })
          }} variant='contained' sx={{ borderRadius: '0', boxShadow: "none" }}>See More</Button>
        </div>
      </div>
    </div>
  )
}
