
import Koneswaram from '../../../images/history/Koneswaram.jpg'
import ImageBox from './ImageBox'

const Trincomalee = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">TRINCOMALEE</div>

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        Famous for its deep, sapphire-blue harbour, its powdery beaches and charming Hindu culture, Trincomalee has always been
                        one of Sri Lanka’s most captivating destinations. Located along the far-flung north eastern coast of Sri Lanka, it is easy to reach
                        from the Cultural Triangle, and can be accessed via air from a handful of locations in Sri Lanka.</div>
                </div>

                <div className='attraction-park-box'>
                    <h3>HISTORY OF TRINCOMALEE</h3>
                    <div className="attraction-park-discription">
                        Any introduction to Trincomalee begins at its magnificent natural harbour, considered for millennia to be one of the finest in
                        Asia. It was used as a trading port throughout the Anuradhapura and Polonnaruwa periods, and was later the focus of battles
                        during the colonial period when succeeding European nations vied for control of the island. When it was the turn of the British
                        to colonise the island, then known as Ceylon, the Royal Navy established a naval base at Trincomalee which was later attacked
                        by Japanese fighter pilots during World War II. At the Trincomalee War Cemetery, you can wander between rows of white
                        gravestones belonging to soldiers of the British Empire killed during the war.</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>WHERE IS TRINCOMALEE?</h3>
                    <div className="attraction-park-discription">
                        Trincomalee is located on Sri Lanka’s north eastern coast. Stretching either side of a narrow peninsular, it is flanked by some of
                        the most idyllic white-sand beaches in Sri Lanka. Trincomalee is a two-hour drive from Anuradhapura, in Sri Lanka’s Cultural
                        Triangle, and 265-kilometres from Colombo. You can also fly into Trincomalee’s domestic airport.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>WHAT ARE THE HIGHLIGHTS OF TRINCOMALEE?</h3>
                    <div className="attraction-park-discription">
                        Some of Trincomalee’s highlights include a spray of white-sand beaches, exceptional diving, whale watching voyages, the history
                        and culture of Fort Frederick and Konneswaram Temple, and the chance to soak up a way of life characterised by Tamil Hindu
                        culture.<br></br>
                        The coastal waters off Trincomalee are blessed with an abundance of marine life. Diving and snorkelling are popular past-times,
                        and no-where is better for this than Pigeon Island National Park, a 10-minute’ boat ride from Nilaveli beach. Named after the
                        blue rock pigeons that have colonised the twin islands here, the vast majority of the national park is underwater. Coral reefs
                        harbor turtles, black tip reef sharks and over 300 varieties of fish. Tickets for the national park need to be purchased prior to
                        arrival on the island.</div>

                </div>
                <div className='attraction-park-box'>
                    <h3>BEST BEACHES IN TRINCOMALEE</h3>
                    <div className="attraction-park-discription">
                        Trincomalee’s beaches are known as some of the most beautiful in Sri Lanka. Be greeted by powder soft sand lapped by clear
                        water and a backdrop of crackly-leafed sugar palms. Each of Trincomalee’s beaches has its own charm. Uppavali, north of
                        Trincomalee, is the most developed of the town’s beaches and backed by large resort hotels. Ten kilometres beyond Uppaveli
                        is Nilaveli, a wide expanse of beach with a collection of low-slung hotels and guesthouses. Much further north at Kuchchaveli is
                        a near-deserted beach backed by lush palms that stretches as far as the eye can see, and just one resort, Uga Jungle Beach.</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>WHEN IS THE BEST TIME TO VISIT TRINCOMALEE?</h3>
                    <div className="attraction-park-discription">
                        Trincomalee may be visited throughout the year, however its summer season, between April and October, is generally best for
                        dry, sunny conditions and clear water. The north western monsoon occurs between November and February, however since
                        this part of the island falls under the Dry Zone, rainfall (compared to the southern monsoon) is much less.<br></br>
                        The ocean off Trincomalee is one of the best places to go whale watching in Sri Lanka. Between the months of May and
                        September, catamarans and boats head out daily to the deep sea in search of blue and sperm whales, often also seeing turtles,
                        flying fish and sea eagles. Seeing whales in their natural habitat is a breathtaking experience that should not be missed. Some
                        hotels, such as Uga Jungle Beach, offer private whale watching trips.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>EXPLORING FORT FREDERICK</h3>
                    <div className="attraction-park-discription">
                        If you can tear yourself away from Trincomalee’s beaches, we highly recommend a visit to Fort Frederick and the Konneswaram
                        Temple. Fort Frederick dates back to the Portuguese period in Sri Lanka yet many buildings were rebuilt or redesigned by the
                        Dutch, and later, the British. Most of the fort is occupied by the Sri Lankan military (sharing the shady grounds with herds of
                        spotted deer), however you can explore the old fort and admire many of its most historic buildings on foot. Look out for the
                        Georgian-era Wellesly House
                    </div>
                </div>
                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <h3>VISITING THE KONNESWARAM TEMPLE</h3>
                    <div className="attraction-park-discription">
                        Perched 400 feet above the sea, at the southern extremity of the peninsula that separates the inner from the outer Trincomalee
                        harbour, lies a magnificent temple dedicated to Lord Shiva (one of only five such temples in Sri Lanka). Referred to by European
                        writers of the 18th and 19th centuries as the ‘Temple of a Thousand Pillars’, it is a sacred ground for Hindu devotees. The temple
                        sits prominently in Fort Frederick on Swarmi Rock – a promontory boasting spectacular views over Trincomalee Harbour. If
                        you’re visiting the temple, we’d recommend wearing (or bringing) socks and covering your knees and shoulders.<br></br>
                        Do visit the Sri Pathirakali Amman Kovil to gain a colourful insight into Hindu religious culture – the aroma of incense will hit you
                        from the street. The exterior structure is one of the most vivid in Sri Lanka, evocatively decorated with figures, pillars and Hindu
                        gods, whilst inside the complex of connected shrines, murals visualise stories from the scriptures.
                    </div>
                </div>

                <ImageBox imageUrl={Koneswaram} />
            </div>
        </div>
    )
}

export default Trincomalee
