import './linearavailability.css'

import { Box, Button, ButtonGroup, Divider, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { DateCalendar, DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { max } from 'date-fns';
import { CounterButton } from '../CounterButton/CounterButton';


const inputBtnStyle = {
    // borderRadius: 0,
    width: 1,
    fontSize: '16px',
    color: 'black',
    // borderColor: 'black'
}

const checkBtnStyle = {
    borderRadius: 0,
    width: '300px',
    height: '58px',
    boxShadow: 'none',
    fontSize: '16px',
    // marginRight: '10px'
}

const checkBtnStyle_xs = {
    borderRadius: 0,
    width: '100%',
    // height: '58px',
    boxShadow: 'none',
    fontSize: '16px',
    // marginRight: '10px'
}

const datePickerStyle = {
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '0',
    padding: '2px 12px 10px 12px',
    
    "& .MuiInputBase-input": {
        fontSize: '16px',
        padding: '0' 
    },
}



const VerticalDevider = () => {
    return <div className="l-vertical-line"></div>
}

export const LinearAvailability = ({ check, dispatch }) => {

    // console.log(check);

    // const [checkInDate, setCheckInDate] = useState(dayjs(check.in));
    const [adults, setAdults] = useState(check.adults)
    const [children, setChildren] = useState(check.children)

    const [valueIn, setValueIn] = useState(dayjs(check.in))
    const [valueOut, setValueOut] = useState(dayjs(check.out))

    function handleChangeIn(e) {
        const date = new dayjs(`${e.get('month')+1}-${e.get('date')}-${e.get('year')}`)
        setValueIn(date)
        
        if(date.diff(valueOut, 'day') >= 0) {
            // console.log('chenged',date.add(1, 'day'));
            setValueOut(date.add(1, 'day'))
        }
    }

    function handleChangeOut(e) {
        // console.log(e, valueIn);

        if (e.isSame(valueIn) || e.isBefore(valueIn)) {
            // console.log('Same or before');
            setValueOut(valueIn.add(1, 'day'))
        } else {
            setValueOut(new dayjs(`${e.get('month')+1}-${e.get('date')}-${e.get('year')}`))
        }

    }

    function checkAvailability() {


        const date1 = new Date(valueIn);
        const date2 = new Date(valueOut);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        dispatch({
            type: 'change',
            page: 'rooms',
            data: {
                state: 'ability',
                group: null,
                check: {
                    in: valueIn,
                    out: valueOut,
                    nights: diffDays,
                    adults,
                    children
                }
            }
        })
    }
    

    return (
        <div className="linearavailability-box notranslate">
            <Box sx={{ boxShadow: 0, backgroundColor: 'white', gap: '5px', display: { xs: 'none', md: 'flex'} }} className="linearavailability">
                <Box sx={{ width: 1, display: 'flex', gap: '5px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="l-check-box">
                            <p className="l-check-title">Check-in</p>
                            <DesktopDatePicker format='DD/MM/YYYY' disablePast onChange={handleChangeIn} className='destopDataPicker-btn' sx={datePickerStyle} defaultValue={dayjs(check.in)} />
                        </div>
                        <VerticalDevider />
                        <div className="l-check-box">
                            <p className="l-check-title">Check-out</p>
                            <DesktopDatePicker format='DD/MM/YYYY' disablePast onChange={handleChangeOut} value={valueOut} className='destopDataPicker-btn' sx={datePickerStyle} />
                        </div>
                    </LocalizationProvider>
                    
                    <VerticalDevider />
                    <CounterButton label="Adults" value={adults} setValue={setAdults}/>
                    <VerticalDevider />
                    <CounterButton label="Children" value={children} setValue={setChildren}/>
                </Box>
                <Button sx={checkBtnStyle} onClick={checkAvailability} variant='contained' >Check Availability</Button>
            </Box>

            <Box sx={{ boxShadow: 0, backgroundColor: 'white', display: { xs: 'flex', md: 'none'} }} className="linearavailability-xs">
                <Box sx={{ width: 1, display: 'flex', gap: '5px', justifyContent: 'center', borderBottom: {xs: '1px solid #91b1ab'} }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="l-check-box">
                            <p className="l-check-title">Check-in</p>
                            <DatePicker disablePast onChange={handleChangeIn} className='destopDataPicker-btn' sx={datePickerStyle} defaultValue={dayjs(check.in)} />
                        </div>
                        <VerticalDevider />
                        <div className="l-check-box">
                            <p className="l-check-title">Check-out</p>
                            <DatePicker minDate={valueIn}  onChange={handleChangeOut} value={valueOut} className='destopDataPicker-btn' sx={datePickerStyle} />
                        </div>
                    </LocalizationProvider>

                    {/* <VerticalDevider /> */}

                </Box>
                <Box sx={{ width: 1, display: 'flex', gap: '5px' }}>
                    <CounterButton label="Adults" value={adults} setValue={setAdults} xm={true}/>
                    <VerticalDevider />
                    <CounterButton label="Children" value={children} setValue={setChildren} xm={true}/>
                </Box>
                <Button size='sm' sx={checkBtnStyle_xs} onClick={checkAvailability} variant='contained' >Check Availability</Button>
            </Box>

        </div>
    )
}
