import { Box, Button, ButtonGroup, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'


const counterButtonStyle = {
    width: 1, 
    backgroundColor: 'white',
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    gap: '15px',
    padding: '5px',
    // paddingRight: '10px',
    // maxWidth: '190px',
    // border: '1px solid #bbbbbb',
    borderRadius: '4px',
    // float: 1
    
}
const counterButtonStyle_xs = {
    width: 1, 
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'space-between',
    alignItems: 'start',
    // gap: '5px',
    // padding: '5px',
    paddingLeft: '10px',
    // maxWidth: '190px',
    // border: '1px solid #bbbbbb',
    borderRadius: '4px',
    paddingBottom: '10px'
    // float: 1
    
}


export const CounterButton = ({ label, value, setValue, xm = false }) => {

    // const [value, setValue] = useState(defaultValue);

    const state = label === 'Children'? true : false


    return (
        <Box sx={xm ? counterButtonStyle_xs : counterButtonStyle}>
            <Typography variant="subtitle1" sx={{fontSize: {xs: '12px', float: 'left'}}}>{label}</Typography>
            {/* <ButtonGroup size='small'>
                <Button onClick={() => {setValue((value > 1) ? value - 1 : (label === 'Children' ? 0 : 1)) }}>-</Button>
                <Button >{value}</Button>
                <Button onClick={() => {setValue(value + 1) }}>+</Button>
            </ButtonGroup> */}
             <FormControl fullWidth>
                        {/* <p className="check-input-title">Children</p> */}
                        {/* <InputLabel id="demo-simple-select-label">Children</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            // label="Children"
                            onChange={(e) => setValue(e.target.value)}
                            sx={{height: '40px' , borderRadius: '0', fontSize: '22px', marginRight: '10px'}}
                        >
                            <MenuItem value={state ? 0 : 1}>{state ? 0 : 1}</MenuItem>
                            <MenuItem value={state ? 1 : 2}>{state ? 1 : 2}</MenuItem>
                        </Select>
                    </FormControl>
        </Box>
    )
}
