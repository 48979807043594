import { Container } from '@mui/material'
import './testimonialcard.css'

export const TestimonialCard = ({message, author, flag}) => {
    return (
        <div className='testimonoal-box'>
            <div className="testimonoals-msg">
                <div className="testimonoals-msg-text">
                    <p>{message}</p>
                </div>
                <div className='testimonoals-msg-expand-box'></div>
            </div>
            <div className="testimonoals-msg-auther">
                <img width={30} src={flag}/>
                <p>- {author}</p>
            </div>
        </div>
    )
}
