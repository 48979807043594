import './attraction.css'
import Image from '../../images/travel/travel.jpg'
import mapUrl from '../../images/map/map.png'
import { Box } from '@mui/material'
import AttractionCardRoute from './AttractionCardRoute'
import AttractionCard from '../Visit/AttractionCard'
import AttractionCardXS from '../Visit/AttractionCardXS'

import attractions from './attractions'
import TourPackage from './TourPackage'
import DayPackages from './DayPackages'
import TourPackages from './TourPackages'

const Attraction = () => {


    return (
        <Box className='attraction-page'>
            <Box className="attraction-hero-section">
                {/* <Box className="attraction-hero-img"> */}
                {/* <img src={Image} alt="bg" className="attraction-bg-img" /> */}
                {/* </Box> */}
                <Box className="travel-hero-msg-box">
                    <p className="travel-hero-title">Time To Travel<br />Enjoy In the Wild</p>
                    <p className="travel-hero-desciption">Discover the best day outing options in Sri Lanka with our comprehensive directory. From cultural and historical attractions to natural wonders and adventure activities, we have something for everyone. Our listings include detailed descriptions and photos making it easy to plan the perfect tour. Explore all that Sri Lanka has to offer and create unforgettable memories.</p>
                </Box>
                {/* <Box className="attraction-history-box-section"> */}
                    {/* 
                    <Box className="attaction-history-box" style={{ padding: '0' }}>
                        <img src={mapUrl} alt="Map" style={{ maxWidth: '900px', width: '100%' }} /> */}
                    {/* <p className="attraction-history-title">Anuradapura - Map</p>
                        <p className="attraction-history-desciption"> Travel Agents with local connections, knowledge and expertise can make your holiday more canker free and fun filled. They can organise our holiday getting you the best deals and providing you with the hotels, transportation and othe</p> */}
                    {/* </Box> */}
                {/* </Box> */}
            </Box>
            <Box className="attraction-body" sx={{ maxWidth: '1024px' }}>

                <Box className="attraction-body-title-box">
                    <p className="attraction-body-title">Tour Map</p>
                </Box>

                <Box className="attraction-body-title-box" style={{ padding: '0' }}>
                    <img src={mapUrl} alt="Map" style={{ maxWidth: '1024px', width: '100%' }} />
                </Box>

                <Box className="attraction-body-title-box">
                    <p className="attraction-body-title">Attractions In</p>
                </Box>

                <TourPackages />

            </Box>

        </Box>
    )
}

export default Attraction
