import { Button } from '@mui/material'
import './cartcard.css'
import DeleteIcon from '@mui/icons-material/Delete';

export const CartCard = ({ packageTitle, title, price, setCart, index, adults, children, oneperson, onecp }) => {
  // console.log(packageTitle);

  const deleteCart = () => {
    setCart({
      type: 'delete',
      index: index
    })
  }

  // console.log('Test', onecp);


  return (
    <div className='cart-card-box'>
      <div className="cart-details">
        <p className="cart-room-package-title">{packageTitle}</p>
        <p className="cart-room-title">{title}</p>
      </div>
      <div className="cart-control">
        <div className="cart-control-title">
          <div className="cart-price notranslate">US ${price}</div>
          <p className="cart-price-one">adults - {adults} {children > 0 ? `& children - ${children}` : null}</p>
          {
            oneperson && onecp.oneperson > 0? <div className="cart-price-one">One Person: -{onecp.oneperson}$</div> : ''
          }
        </div>
        <Button onClick={deleteCart} size='small' color="error" startIcon={<DeleteIcon />} sx={{ fontSize: '12px' }}>Remove</Button>
      </div>
    </div>
  )
}
