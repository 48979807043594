import './room.css'
import image from '../../images/room1.jpg'
import { Avatar, Button, Chip, Collapse, Divider, Link, Box } from '@mui/material'
import { SelectablePackage } from '../SelectablePackage/SelectablePackage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useReducer, useState } from 'react';
import airConditioningImg from '../../images/facilities/air-conditioner.png'
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
import HeroSlider from 'hero-slider/dist/HeroSlider';
import { MenuNav, SideNav, Slide } from 'hero-slider';
import target from '../../db/target';

const chipStyle = {
  padding: '0 5px',
  margin: '5px'
}


const ImageSlider = ({images}) => {
  
  // console.log(images);

  const endPoint = target + 'images/rooms/'
  
  // const bogliasco = endPoint + images[0];
  // const countyClare = endPoint + images[1];
  // const craterRock = endPoint + images[2];
  // const giauPass = endPoint + images[3];


  return <HeroSlider
    height={''}
    autoplay
    controller={{
      initialSlide: 1,
      slidingDuration: 200,
      slidingDelay: 50,      
    }}
    style={{
      
    }}
  >
    {/* <Overlay>
      <Wrapper>
        <Title>Basic Setup</Title>
        <Subtitle>
          Check out the documentation for more advanced examples.
        </Subtitle>
      </Wrapper>
    </Overlay> */}

    {
      images.map((image, index) => {
        return <Slide key={index} shouldRenderMask label='room image' background={{backgroundImageSrc: endPoint + image}}/>
      })
    }

    {/* <Slide
      shouldRenderMask
      label="Giau Pass - Italy"
      background={{
        backgroundImageSrc: giauPass
      }}
    />

    <Slide
      // shouldRenderMask
      label="Bogliasco - Italy"
      background={{
        backgroundImageSrc: bogliasco
      }}
    />

    <Slide
      shouldRenderMask
      label="County Clare - Ireland"
      background={{
        backgroundImageSrc: countyClare
      }}
    />

    <Slide
      shouldRenderMask
      label="Crater Rock, OR - United States"
      background={{
        backgroundImageSrc: craterRock
      }}
    /> */}

<SideNav
    isPositionedRight={false}
    position={{
      top: '50%',
      left: '0%',
      transform: 'translateY(-50%)'
    }}
  />
  </HeroSlider>
}

const reducerPrice = (state, action) => {
  if(action.type === 'price'){
    return action.price
  }else {
    return state
  }
}


export const Room = ({ room=[], setCart, packages=[], available }) => {

  

  const  packageSelect = packages
  
  const {code, title, description, images, facilities} = room;
  const _title = title

  // console.log(facilities);
  
  const [expandSelectable, setExpandSelectable] = useState(false);
  const[price, setPrice] = useReducer(reducerPrice, packageSelect.length > 0 ? packageSelect[0].price : 0)

  const endPoint = target + 'images/icons/'

  return (
    <div className="room-box">
      <div className="image-box">
        {/* <img src={image} width={'100%'} /> */}
        <ImageSlider images={images} />
      </div>

      <div className="room-details-box">
        <div className="room-title-box">
          <p className="room-title">{title}</p>
          <p className="room-description" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}>{description}</p>
        </div>
      </div>

      <div className="room-facilities-box">
        {facilities.map(({ iconUrl, title }, index) => {
          // console.log(endPoint + iconUrl);
          return <Chip key={index} sx={chipStyle} label={title} avatar={<Avatar alt="avatar" src= {endPoint + iconUrl}/>} />
        })}
      </div>


      <Collapse in={expandSelectable} >
        <div className="room-select-box">
          <Divider />
          {packageSelect.map(({title, description, price }, index) => {
            return <Box key={index}>
              <SelectablePackage setCart={setCart} setPrice={setPrice} code={code} title={_title} packageTitle={title} packageDiscription={description} packagePrice={price} available={available}/>
              <Divider />
            </Box>
          })}
        </div>
      </Collapse>

      <div className="room-selectable">
        <div className="selected-price-box">
          <p className="selected-price notranslate">US ${price}</p>
          <p className="selected-price-details">two person/ pernight</p>
        </div>
        <Button onClick={() => { setExpandSelectable(!expandSelectable) }} endIcon={expandSelectable ? <ExpandLessIcon /> : <ExpandMoreIcon />}>Select Pakages</Button>
      </div>
    </div>
  )
}