import ImageWilpattu from '../../../images/history/Wilpattu.png'
import ImageBox from './ImageBox'

const Wilpattu = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Wilpattu National Park</div>


                <div className='attraction-park-box'>
                    {/* <h2>Wilpattu National Park</h2> */}
                    <div className="attraction-park-list">
                        <div>Location</div>
                        <div>– Approximately 188 kms away from capitol Colombo</div>
                        <div>Enterance Gate</div>
                        <div>– Hunuwilgama Enterance (Main Gate)</div>
                        <div>Park visiting hours</div>
                        <div>– 6 AM to 6 PM</div>
                        <div>Mode of safaris</div>
                        <div>– Only Jeep safaris</div>
                        <div>Best time of the day to visit the park</div>
                        <div>– 6 AM to 10 AM and 3 PM to 6 PM</div>
                        <div>Type of animals in the park</div>
                        <div>– Leopards, Elephants, Bears, Deer, Crocodiles, Reptiles and many verities of birds</div>
                        <div>Closest town to the park entrance</div>
                        <div>– Nochchiyagama, Puttalam and Anuradhapura</div>
                        <div>Best months to visit the park</div>
                        <div>– Throughout the year. But February to October is the prime time</div>
                        <div>How to come</div>
                        <div>– Hired private car or van</div>
                    </div>
                    <div className="attraction-park-discription">
                        &nbsp;Wilpattu sanctuary was decalred as a National Park in 1938, Wilpattu National Park is located on the west coast close to the
                        historical city of Anuradhapura .The dry zone jungle is thickly grown. Wilpattu Natonal Park is home for many villus, or natural
                        lakes which dot the landscape in the Wilpattu National Park. Except for two, These lakes contain rainwater, thus are important
                        for resident and migratory water-birds. <br></br>
                        &nbsp;The history of the park is also of interest with ancient ruins having been discovered in Wilpattu National Park. Queen named
                        “Kuweni” (considered to be the mother of the Sinhala race) is said to have lived in the place known as Kalli Villu. Historical
                        evidence also shows the fact that Prince Saliya, son of King Dutugemunu lived in Wilpattu over 2,000 years ago.
                    </div>
                </div>


                <div className='attraction-park-box'>
                    <h3>Wildlife in the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       There are approximately 30 species of mammals in the Wilpattu National Park which include the Sri Lankan Elephant, Sri
                        Lankan Leopard, Lankan Sloth Bear, Sri, Spotted Deer, Buffalo, Sambar and Mongoose.</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Birdlife in the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       Wetland bird species found in the Wilpattu National Park include the Garganey, Pin tail, Whistling Teal, Spoonbill, White Ibis,
                        Large White Egret, Cattle Egret and Purple Heron. Also, many species of Gulls, Terns, Owls, Kites and Eagles are also lives here.
                        The endemic Sri Lanka Jungle fowl, Little Cormorant and the Painted Stork can also Can be seen.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Reptiles and Amphibians in the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       Monitor Lizard, Mugger Crocodile, Common Cobra, Rat Snake, Indian Python, Pond Turtle and the Soft Shelled Turtle can be
                        seen in Wilpattu National Park</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Flora in the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       Wilpattu is home for many species of flora and they are Palu (Manilkara hexandra), Satin (Chloroxylon swietenia), Milla (Vitex
                        altissima), Weera (Drypetes sepiaria), Ebony (Diospyros ebenum) and Wewarna (Alseodaphne semecarpifolia).</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Climate in the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       The annual temperature in the Park is between 27°C to 30°C and its annual rainfall is approximately 900 mm. The Wilpattu
                        National Park is situated in the dry zone but most of the time flora is very greenish and has plenty of water sources. The period
                        between September to December is the rainy season while inter-monsoonal rains are expected between March to May. The
                        period of drought in the Park extends from June to early September.</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>How to reach Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       Wilpattu is located 30 kms north of Puttalam, approximately 190 kms from Colombo. Wilpattu National Park extends from the
                        northwest coast towards inland ancient capital city of Anuradhapura which is about 50 km to the east of the Park, covering 425
                        sq miles approximately. The route from Colombo is via Negombo, Chilaw, Palaviya and Puttalam, The Park office and the main
                        entrance where one can obtain a ticket and enter the park is at Hunuwilgama, 7 km from the turn-off from Puttalam -
                        Anuradhapura road.</div>

                </div>
                <div className='attraction-park-box'style={{ border: 'none', marginBottom: '0' }}>
                    <h3>The best times to visit the Wilpattu National Park</h3>
                    <div className="attraction-park-discription">
                       Though this National Park can be visited throughout the year, February to October is the prime time.</div>
                </div>
                <ImageBox imageUrl={ImageWilpattu} />
            </div>
        </div>
    )
}

export default Wilpattu
