import './rooms.css'
import { Availability } from '../../componets/Availability/Availability'
import { Room } from '../../componets/Room/Room'
import { Cart } from '../../componets/Cart/Cart'
import { LinearAvailability } from '../../componets/LinearAvailability/LinearAvailability'
import { useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import CheckOut from '../../componets/CheckOut/CheckOut'
import { Box, CircularProgress, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'
import target from '../../db/target'

const reducer = (state, action) => {
  let array;

  if (action.type === 'select') {
    array = [...state]

    if (array.length !== 0) {

      let has = false;

      let newArray = array.map((arr) => {
        if (arr.code === action.code) {
          has = true
          return {
            code: action.code,
            packageTitle: action.packageTitle,
            title: action.title,
            price: action.price,
          }
        } else {
          return arr
        }
      });

      if (has === false) {
        array.push({
          code: action.code,
          packageTitle: action.packageTitle,
          title: action.title,
          price: action.price,
        })
        return array
      }

      return newArray

    } else {
      array.push({
        code: action.code,
        packageTitle: action.packageTitle,
        title: action.title,
        price: action.price,
      })
    }
    return array

  } else if (action.type === 'delete') {
    array = [...state]
    array.splice(action.index, 1)
    return array
  } else if (action.type === 'clear') {
    return []
  } else {
    return state
  }
}



let ok = false

export const Rooms = ({ data, dispatch }) => {
  // --------------------------------------
  const cost = {
    oneperson: 10,
    children: 5,
    age: 6
  }
  // --------------------------------------

  const location = useLocation()

  let check = data.check

  const [rooms, setRooms] = useState([])
  const [packages, setPackages] = useState([])
  const [packagesprices, setPackagesPrices] = useState([])
  const [available, setAvailable] = useState(false)
  const [loading, setLoading] = useState(false)

  const [cart, setCart] = useReducer(reducer, [])

  function getAll() {

    setAvailable(false)

    axios.get(target + 'api/client/rooms/all')
      .then(res => {
        setRooms(res.data)
        setLoading(false)
      })
      .catch(err => console.log(err))

    axios.get(target + 'api/client/rooms/season')
      .then(res => {
        setPackages(res.data)
      })
      .catch(err => console.log(err))
  }

  function getGroup(group) {

    setAvailable(false)

    axios.get(target + 'api/client/rooms/group', {
      params: { group }
    })
      .then(res => {
        setRooms(res.data)
        setLoading(false)
      })
      .catch(err => console.log(err))

    axios.get(target + 'api/client/rooms/season')
      .then(res => {
        setPackages(res.data)
      })
      .catch(err => console.log(err))
  }

  async function getPackages() {
    // console.log('read');

    try {
      const res = await axios.get(target + 'api/client/rooms/roomprices')
      // console.log(res.data);
      setPackages(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function getPackagePrices() {
    try {
      const res = await axios.get(target + 'api/client/rooms/packages')
      // console.log(res.data);
      setPackagesPrices(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  function getAvailability(check) {

    setRooms([])
    check = data.check
    setAvailable(true)

    const payload = {
      in: (check.in).format('YYYY-MM-DD'),
      out: (check.out).format('YYYY-MM-DD')
    }

    // console.log(check, payload);

    axios.post(target + 'api/client/booking/range', payload)
      .then(codes => {

        axios.post(target + 'api/client/rooms/available', { codes: codes.data })
          .then(rooms => {
            setRooms(rooms.data)
            setLoading(false)
          })
          .catch(err => console.log(err))

      })
      .catch(err => console.log(err))

    // axios.get(target + 'api/client/rooms/roomprices')
    //   .then(res => {
    //     console.log(res);
    //     setPackages(res.data)
    //   })
    //   .catch(err => console.log(err))

      axios.post(target + 'api/client/rooms/season', payload)
      .then(res => {
        console.log(res);
        setPackages(res.data)
      })
      .catch(err => console.log(err))
  }

  function getRooms() {
    setLoading(true)
    setRooms([])
    setCart({ type: 'clear' })
    if (data.state === 'all') getAll()
    else if (data.state === 'group') getGroup(data.group)
    else if (data.state === 'ability') getAvailability(data.check)
  }

  useEffect(() => {
    getRooms()
    getPackagePrices()

  }, [data.state, check.adults, check.children, check.in, check.out])

  const [mode, setMode] = useState(true)
  const [cartData, setCartData] = useState({})

  return (

    mode
      ?
      <div className="rooms-page">

        <LinearAvailability check={check} dispatch={dispatch} />

        <Stack className="rooms-box" direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'start' }}>
          <div className="rooms-section">
            <div className="rooms-title-box"><p className="rooms-title">Choose your rooms</p></div>
            {
              loading ?
                (
                  <div style={{ width: '100%', textAlign: 'center' }}> <CircularProgress color="success" /> </div>
                )
                :
                (
                  rooms.length > 0 ? rooms.map((room, index) => {

                    // console.log('read: 0');
                    if (packages.length <= 0) {
                      getPackages()
                    } else {
                      // console.log(packages);
                      const pack = packages.filter((item) => {
                        if (room.code === item.code) return item.prices
                      })
                      return <Room key={index} room={room} setCart={setCart} packages={pack[0].prices} available={available} packagesprices={packagesprices} />
                    }
                  })
                    : ' There is no any rooms available. Please try another date'
                )
            }
          </div>
          <Box className="avalability-and-cart" sx={{ top: { xs: '30px', sm: '130px', md: '30px' } }}>
            <Cart cart={cart} setCart={setCart} check={check} methods={{ setMode, setCartData }} packagesprices={packagesprices} available={available} />
          </Box>
        </Stack>
      </div>
      :
      <CheckOut methods={{ setMode, setCartData }} packagesprices={packagesprices} cartData={cartData} check={check}></CheckOut>
  )
}
