import { Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import target from '../../db/target'


// const totalpay = [
//     {
//         title: 'Discount',
//         amount: 23,
//         method: 'percentage',
//         operator: '+',
//         limit: 23
//     },
//     {
//         title: 'Discount For More Rooms',
//         amount: 16,
//         method: 'value',
//         operator: '-',
//         limit: 50
//     },
//     {
//         title: 'Tax',
//         amount: 2,
//         method: 'percentage',
//         operator: '-',
//         limit: 67
//     }
// ]

const CheckOutTotalPriceCard = ({ cartData, check }) => {

    // console.log(cartData);

    const [adults, setAdults] = useState(cartData.adults)
    const [children, setChildren] = useState(cartData.children)


    useEffect(() => {
        setAdults(cartData.adults)
        setChildren(cartData.children)
    }, [cartData.adults, cartData.children])


    return <div>
        <div className='co-cart-total-box-r'>
            <div className='co-cart-total-box-c'>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Rooms:  <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{cartData.cart.length}</span></Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Check In: <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{check.in.format('YYYY-MM-DD')}</span></Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Adults: <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{adults}</span></Typography>
            </div>

            <div className='co-cart-total-box-c'>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }} >Nights:  <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{check.nights}</span></Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Check Out: <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{check.out.format('YYYY-MM-DD')}</span></Typography>
                <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>Children (5-11yr):  <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{children}</span></Typography>
            </div>
        </div>
        <hr />
        <div className='co-cart-total-box-price'>
            <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }} >US$ {cartData.total}.00</Typography>
        </div>
    </div>
}


const CheckOutTotalCard = ({ totalpay, cartData }) => {

    return <div className='co-cart-total-offer-box'>
        <div className="co-cart-offer-title">
            {
                totalpay.map((item, index) => {

                    if (cartData.total < item.limit) return ''

                    return <Typography key={index} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }} >{item.title}:</Typography>
                })
            }
        </div>
        <div className="co-cart-offer-value">
            {
                totalpay.map((item, index) => {


                    if (cartData.total < item.limit) return ''

                    return item.method === "presentage" ?
                        <Typography key={index} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }} >{`${item.operator} US$ ` + (cartData.total / 100 * item.amount).toFixed(2)} </Typography>
                        :
                        <Typography key={index} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }} >{`${item.operator} US$ ${item.amount}.00`}</Typography>
                })
            }
        </div>
    </div>
}

const CheckOutTotal = ({ cartData, check, totalPriceDispatch, discouttaxDispatch }) => {

    const tpWindow = useRef()

    // console.log(tpWindow);
    const [totalpay, setTotalPay] = useState([])
    const [total, setTotal] = useState(cartData.total)
    var resdata = [];
    const [currencyData, setCurrencyData] = useState({})


    function getCurrency() {
        axios.get(target + 'api/currency')
            .then(res => {
                setCurrencyData(res.data)
            })
    }

    function calTotal(res_data, _total) {

        // console.log(res.data);

        discouttaxDispatch({
            type: 'set',
            data: res_data
        })

        if ((res_data).length > 0) {
            (res_data).map((item) => {
                if (item.limit < total) {
                    if (item.method === "presentage") {
                        if (item.operator === '-') {
                            _total -= cartData.total * item.amount / 100
                        } else if (item.operator === '+') {
                            _total += cartData.total * item.amount / 100
                        }
                    } else if (item.method === "value") {
                        if (item.operator === '-') {
                            _total -= item.amount
                        } else if (item.operator === '+') {
                            _total += item.amount
                        }
                    }
                }
            })
        }


        // console.log(res_data, _total, total);

        setTotal(_total)

        totalPriceDispatch({
            type: 'change',
            data: {
                total: _total,
                totalObj: tpWindow.current
            }
        })

        setTotalPay(res_data)
    }


    useEffect(() => {

        getCurrency()


        axios.get(target + 'api/client/discount-tax/now')
            .then(res => {

                var _total = total

                console.log('Discoutn: ', res);


                discouttaxDispatch({
                    type: 'set',
                    data: res.data
                })

                if ((res.data).length > 0) {
                    (res.data).map((item) => {
                        if (item.limit < total) {
                            if (item.method === "presentage") {
                                if (item.operator === '-') {
                                    _total -= cartData.total * item.amount / 100
                                } else if (item.operator === '+') {
                                    _total += cartData.total * item.amount / 100
                                }
                            } else if (item.method === "value") {
                                if (item.operator === '-') {
                                    _total -= item.amount
                                } else if (item.operator === '+') {
                                    _total += item.amount
                                }
                            }
                        }
                    })
                }


                // console.log(res.data, _total, total);

                setTotal(_total)

                totalPriceDispatch({
                    type: 'change',
                    data: {
                        total: _total,
                        totalObj: tpWindow.current
                    }
                })

                setTotalPay(res.data)

            })
            .catch(err => console.log(err))



    }, [])

    useEffect(() => {
        calTotal(totalpay, cartData.total)
    }, [cartData.total])




    return (
        <div className='co-cart-box-total notranslate' ref={tpWindow}>
            <div className="co-cart-total-box">

                <CheckOutTotalPriceCard cartData={cartData} check={check} />

                <CheckOutTotalCard totalpay={totalpay} cartData={cartData} />


                <hr />
                <div className='co-cart-total-final'>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }} >Total: </Typography>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }} >US$ {total.toFixed(2)}</Typography>
                </div>

                <div className='total-currency-box'>
                    <p className="">~ EUR: {(currencyData.EUR * total).toFixed(2)}</p>
                    <p className="">~ LKR: {(currencyData.LKR * total).toFixed(2)}</p>
                </div>

            </div>

        </div>
    )
}

export default CheckOutTotal
