
import ParakramaSamudra from '../../../images/history/ParakramaSamudra.jpg'
import PalaceofKingParakramabahu from '../../../images/history/PalaceofKingParakramabahu.jpg'
import ShiwaDewalaya from '../../../images/history/ShiwaDewalaya.jpg'
import ThuparamaIN from '../../../images/history/ThuparamaIN.jpg'
import Vatadage from '../../../images/history/Vatadage.jpg'
import NissankaLathaMandapaya from '../../../images/history/NissankaLathaMandapaya.jpg'
import Sathmahal from '../../../images/history/Sathmahal.jpg'
import Hatadage from '../../../images/history/Hatadage.jpg'
import Galpotha from '../../../images/history/Galpotha.jpg'
import Pabalu from '../../../images/history/Pabalu.jpg'
import Rankoth from '../../../images/history/Rankoth.jpg'
import RankotVihara from '../../../images/history/RankotVihara.jpg'
import KiriVehera from '../../../images/history/KiriVehera.png'
// import Pabalu from '../../../images/history/'  lankathilak
import GalVihara1 from '../../../images/history/GalVihara1.jpg'
// import Pabalu from '../../../images/history'  Lotus bath
import Thiwanka from '../../../images/history/Thiwanka.png' 

import ImageBox from './ImageBox'

const Polonnaruwa = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Polonnaruwa</div>

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        Polonnaruwa (Sinhalese – පොළොන්නරුව Tamil – பொலநறுவை  was the country’s capital from the 11th– 13th Century. Polonnaruwa Sri Lanka is considered one of the best preserved historic cities in the world. Polonnaruwa was declared a UNESCO World Heritage Site, due to its abundance of historic remains.
                        Most commonly referred to as Sri Lanka’s second ancient capital, the city of Polonnaruwa is nevertheless a magnificently preserved city with rich archaeological monuments and natural beauty. Located in Sri Lanka’s North Central Province, Polonnaruwa has both its ancient city, an adjoining “old town” as well as a “new town” located about 6km away. The archaeological sites are in a compact layout, within easy distance of each other, and tours are easily arranged either on foot or more popularly explored on bicycle.
                        As in Anuradhapura, Polonnaruwa too has its share of Buddhist sites, and tour buses of pilgrims can be often seen visiting the stupas and monasteries. But Polonnaruwa also boasts an impressive array of ruined palaces and courtyards, pleasure gardens and intricate networks of bathing tanks, as well as carvings and sculptures and a rich display of artistry. The ancient city was declared a World heritage Site by UNESCO in 1982.

                    </div>
                </div>

                <div className='attraction-park-box'>
                    <h3>Parakrama Samudra</h3>
                    <div className="attraction-park-discription">
                        One of the most striking features in Polonnaruwa is the vast Parakrama Samudra (Sea of Parakramabahu), an irrigation tank built, as the name indicates by King Parakramabahu the Great.  This was his largest irrigation project and covers an area of more than 15 Km2.The dam (or bund, as it is known in Sri Lanka, is almost 14 Km long and 12 metres high.The waters of the Parakrama Samudraya circle the ancient city and its environs, and in ancient times served as a moat against invading armies. An intricate network of irrigation canals supplied water from this reservoir to surrounding paddy fields, ensuring that agricultural production could be carried out year-round. The hitherto rain-fed agricultural practices were transformed, and Polonnaruwa became the rice growing capital of ancient Lanka. <br />
                        The Dipuyyana (Island Garden) is on the promontory by the rest house. When King Parakramabahu had built his vast lake, he selected this lovely spot for a royal garden. Later King Nissankamalla liked it so much that he built his palace and council chambers there.
                    </div>
                    <ImageBox imageUrl={ParakramaSamudra} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Royal Palace and Council Chambers</h3>
                    <div className="attraction-park-discription">
                        Among the most noteworthy buildings in ancient Polonnaruwa was the Royal Palace of King Parakramabahu I. The Palace must have been an imposing edifice, and the chronicles describe it as “seven stories high, furnished with a thousand chambers”. Entering the palace from the south, you come to great hall (31 Metres long and 13 metres wide), which was probably an audience hall. Although the Palace itself is in ruins, the frieze of elephants in the main Audience Hall and the many carvings and moonstones along the entry ways to the palace buildings are worth exploring. <br />
                        Across the way is the council chamber of Parakramabahu – embellished with lion portals, graceful pillars and a moonstone (a delicately carved stepping stone). The building is supported by powerful bas-relief elephants around the base. Each one is different from the next.
                    </div>
                    <ImageBox imageUrl={PalaceofKingParakramabahu} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Siva Shrine – Siva Devale No:1</h3>
                    <div className="attraction-park-discription">
                        A Hindu Temple of chaste and restrained line dedicated to God Siva. This temple dates to the later Polonnaruwa period, and was probably built during a period of Indian domination in the thirteenth century. A number of superb bronzes in mature Choler style were found at this temple, and these can be seen at the Colombo Museum.
                    </div>
                    <ImageBox imageUrl={ShiwaDewalaya} />
                </div>
                <div className='attraction-park-box'>
                    <h3>The Thuparama in Polonnaruwa</h3>
                    <div className="attraction-park-discription">
                        This oblong brick image house occupies the southwest corner of the adjoining quadrangle. The Thuparama, is a large Buddhist image house. <br />
                        Inside the building, in the inner sanctum, is a ruined brick and plaster pile, that was once a seated image of the Buddha. The original vaulted roof of the building is still intact. Tropical rains run down the curve of the roof, and like the gargoyles of medieval Europe, Makara (dragon) spouts drain the water to the ground.
                    </div>
                    
                    <ImageBox imageUrl={ThuparamaIN} />
                </div>
                <div className='attraction-park-box'>
                    <h3>The Vatadage</h3>
                    <div className="attraction-park-discription">
                        This circular relic house possesses an elegance and beauty that is rare even in ancient Sri Lanka. In line with the outer circle of stone pillars is a tastefully ornamented screen wall patterned with four petaled flowers.
                        The flights of access stairs at the cardinal points are of stone and are beautifully carved. At the head of each flight is a Buddha statue in stone. The shrine is lavished with moonstones., guard stones and sculptured writings.
                        <br />
                        The shrine, like all dagobas in Sri Lanka, is oriented towards the cardinal points – north, south, east and west. Four Buddha statues face outwards, with their backs to the dagaba mound.
                    </div>
                    <ImageBox imageUrl={Vatadage} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Nissanka Lata Mandapaya</h3>
                    <div className="attraction-park-discription">
                        A very pretty pavilion, this floral altar in stone has pillars which simulate with rare grace, a lotus on a stalk. This is a sacred hall of unknown purpose, and it was built by Nissankamalla. In the centre of the building is a small model dagaba, with worshippers sculptured around the base.
                    </div>
                    <ImageBox imageUrl={NissankaLathaMandapaya} />
                </div>
                <div className='attraction-park-box'>
                    <h3>The Sathmahal Prasada</h3>
                    <div className="attraction-park-discription">
                        A square pyramidal tower in seven tiers – a Dagaba of novel design. Each side of each tier is ornamented by a figure of a deity in an arched niche. The simple, stepped design is a very ancient form of architecture which occurs in the step pyramids of Egypt, and the ziggurats of Mesopotamia. Another building of this type occurs in Thailand in the monastery of Vat Kukut at Lamphun, and both are probably derived from an earlier Indian prototype.
                    </div>
                    <ImageBox imageUrl={Sathmahal} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Hatadage</h3>
                    <div className="attraction-park-discription">
                        The Shrine of Sixty Relics, which stands opposite the Vatadage. It was claimed to have been built by King Nissankamalla to house the Sacred Tooth Relic and is a handsome edifice, whose plain ashlar walls are very artistically relieved by a double border of faintly incised carving. However despite Nissankamalla’s claim, it may in fact been built by Parakramabahu.
                    </div>
                    <ImageBox imageUrl={Hatadage} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Gal Potha (Stone Book)</h3>
                    <div className="attraction-park-discription">
                        A massive 8 metre long and 4.3 metre wide stone slab, which lies by the side of the Hatadage is inscribed with the deeds of King Nissankamalla. The massive inscription, amongst other things, records Nissankamalla’s brief invasion of India in glowing terms, and details of his relations with foreign states, as far away as modern Pakistan and Indonesia.
                    </div>
                    <ImageBox imageUrl={Galpotha} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Pabulu Vehera in Polonnaruwa</h3>
                    <div className="attraction-park-discription">
                        To the east is the softly rounded dagoba. the Pabulu Vehera or Coral Shrine. It is said to have built by Rupavati, one of the queens of Parakramabahu. There are a number of Buddha images to dating from the later Polonnaruwa period to be seen around the dagoba.
                    </div>
                    <ImageBox imageUrl={Pabalu} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Ran Koth Vehera – The Golden Pinnacle Dagaba</h3>
                    <div className="attraction-park-discription">
                        Built by Nissankamalla. This Dagaba is the largest in Polonnaruwa and measures 550-ft. in girth and 180 feet high. Although it was completed by Nissankamalla, the dagaba was probably largely the work of Rupavati, one of Parakramabahu’s queens.
                    </div>
                    <ImageBox imageUrl={RankotVihara} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Kiri Vehera</h3>
                    <div className="attraction-park-discription">
                        Just ahead of Lankathilake is the  gleaming white Kiri Vehera or Kiri Viharaya (literally, Milk white temple) which is said to have been built by King Parakramabahu in honour of his wife, Queen Subadra. Kiri Vehera is the best preserved of Sri Lanka’s un-restored dagobas.
                        <br />
                        This stupa which is 95 ft. in height with a circumference of 280 ft. is located 800 m North to the famous Ruhunu Maha Kataragama Devalaya.
                    </div>
                    <ImageBox imageUrl={KiriVehera} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Lankathilake- The Image House</h3>
                    <div className="attraction-park-discription">
                        The walls of the majestic Lankathilake even now stand 55 ft. high. In the 12th century, it was one of the most splendid of Buddhist Shrines in Asia. Lankathilake (the ornament of Lanka), was built by Parakramabahu I. The vast standing Buddha inside the shrine was originally about 41 feet (13 metres) with it’s base, but sadly only its torso now stands.
                        <br />
                        The brickwork of the building is of extraordinary variety, from the massive bricks which support the weight of the building, to the tiny, delicately molded bricks of the architectural details. The building must have been immense, and even today it is the most impressive ruin of the ancient city.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Gal Vihare – Rock Shrine</h3>
                    <div className="attraction-park-discription">
                        The Gal Viharaya is a cluster of carved Buddha images, which would have formed part of an extensive monastery on this site. Four separate images of the Buddha are carved from one long slab of granite, a feat of artistry. The Buddha rock sculptures at the Gal Vihare are considered masterpieces of Sri Lankan Buddhist art, and were commissioned by King Parakramabahu I.
                        <br />
                        The first sculpture is a seated Buddha image in a deep meditation, on a throne decorated with lions and thunderbolts and behind the Buddha is a halo. The statue is one of the most impressive examples of Sri Lanka’s sculpture.The next sculpture is inside a cave carved out of solid rock. The seated Buddha is protected by an umbrella. Between the cave and the tall standing figure is a large inscription of Parakramabahu, which records in detail his struggle to unite the Buddhist order, and sets out the reformed rules of conduct.
                        The finest of the images – the standing figure is twenty three feet high (7 metres) with crossed arms. The carving of the serene face is extremely sensitive, and the relaxed, elegant execution of the body and drapery distinguishes this figure as one of the masterpieces of Sri Lankan art.
                        <br />
                        The immense figure of recumbent Buddha measures 46 ft (14 metres). is the last sculpture in the group. The Buddha is lying down, at the final moment of his earthly life, entering Parinibbana, the state of supreme enlightenment. Here too the liquid flow of the robes over the body and the calm expression of the face are beautifully interpreted.
                    </div>
                    <ImageBox imageUrl={GalVihara1} />
                </div>
                <div className='attraction-park-box'>
                    <h3>Lotus Bath</h3>
                    <div className="attraction-park-discription">
                    A pretty rook-cut pool shaped like a stylised lotus, constructed in diminishing concentric lamina, built by Nissankamalla.
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Tivanka Image House</h3>
                    <div className="attraction-park-discription">
                    Largest among the brick – built shrines of Polonnaruwa. In the narrow antechamber, beautiful Devas (demigods), and the Bodhisattvas above, beckon the worshipper into the inner sanctum. Here stands the mighty Tivanka image of the Buddha, depicted in the ‘thrice curved pose.
                    </div>
                    
                    <ImageBox imageUrl={Thiwanka} />
                </div>
               

              
            </div>
        </div>
    )
}

export default Polonnaruwa
