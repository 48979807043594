import { Button, Typography } from '@mui/material'
import './heromessage.css'

export const HeroMessage = () => {

  var distanceToScroll = document.body.scrollHeight - window.innerHeight;
  var speed = 4;





  return (
    <div className='heromessage-box'>
      <Typography sx={{ fontSize: { xs: '38px', sm: '52px', md: '78px' }, fontWeight: 600 }} className="hromessage-title notranslate">Heaven Upon Rice Fields</Typography>
      <Typography sx={{ fontSize: { xs: '16px', md: '18px' } }} className="hromessage-discription">Heaven Upon Rice Fields is one of the most recognizable and top rated accommodation in the historical city of Anuradhapura. Indulge in the scenic views and bond with nature at the most affordable rates.</Typography >
      <h1 className="hromessage-discription">Reserve now !</h1>
      <Button variant='contained' className="hromessage-discription" onClick={() => {
        var scrollInterval = setInterval(function () {
          window.scrollBy(0, speed);

          if (window.pageYOffset >= distanceToScroll) {
            clearInterval(scrollInterval);
          }
          window.addEventListener("click", function () {
            clearInterval(scrollInterval);
          });
        }, 16);
      }} >EXPLORE</Button>
    </div>
  )
}
