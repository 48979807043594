import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import './location.css'

export const Location = () => {

  const theme = useTheme();

  const mq_xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box className="loc-container">
      <Box className="loc-container-box" sx={{padding: {xs: '0px 0px', md: '20px 0px'}}}>
      <Stack className='loc-box' direction={{ xs: 'column-reverse', sm: 'row' }} style={ mq_xs ? {padding: '20px 10px', border: 'none', margin: '0'} : {padding: '20px 40px', border: '1px solid rgb(180, 180, 180)'}}>
        <iframe style={{width: `${mq_xs ? '' : '600px'}`}} className='location-frame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3947.413861797235!2d80.42145387578472!3d8.360884599087548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afcf564f4b4c2db%3A0x3d45209de46c529a!2sHeaven%20Upon%20Rice%20Fields%20Guest%20House!5e0!3m2!1sen!2sus!4v1688149260523!5m2!1sen!2sus" loading="lazy" ></iframe>
        <div className="loc-contact">
          <p className="loc-title notranslate">Get in Touch</p>
          <div className='loc-contact-box'>
            <div className="loc-contact-item" >
              Call : +94 71 2653653 / +94 76 7253653
            </div>
            <div className="loc-contact-item">
              Email : heavenuponricefields@gmail.com
            </div>
            <div className="loc-contact-item" style={ mq_xs ? {padding: '20px 10px'} : {padding: '20px'}}>
              Location :
              <p> 5th Lane, Nuwarawewa watta, Mihinthala Road,</p>
              <p>Jaffna Junction, Anuradhapura</p>
            </div>
          </div>
        </div>
      </Stack>
      </Box>
    </Box>
  )
}
