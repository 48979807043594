import { Fragment, useEffect, useState } from 'react'
import './header.css'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, SwipeableDrawer, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';

const SideHeaderItem = ({ pageNavigate, setState, page, nav }) => {
    return <ListItem>
        <ListItemButton sx={{ color: '#206A5D' }}  onClick={() => {
            pageNavigate(nav, nav === 'rooms' ? 'all' : '')
            setState(false)
            }}>
            <ListItemText sx={{ textAlign: 'center' }} primary={page} />
        </ListItemButton>
    </ListItem>
}


const SideHeader = ({ pageNavigate, setState }) => {
    return <Box sx={{ width: 250 }}>
        <List>
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='Home' nav='home' />
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='Rooms' nav='rooms' />
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='History' nav='history' /> 
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='Attraction' nav='attraction' />
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='Gallery' nav='gallery' />
            <SideHeaderItem pageNavigate={pageNavigate} setState={setState} page='Contact' nav='contact' />
        </List>
    </Box>
}



export const Header = ({ pageData, dispatch }) => {

    // console.log(pageData);


    const [page, setPage] = useState(pageData.page)

    const navigate = useNavigate()

    const pageNavigate = (page, _selected = null, group = null, check) => {

        // if(page === 'rooms') _selected = 'all'

        // console.log(check);


        const state = (_selected !== 'all' || _selected !== null) ? _selected : (page === 'rooms' ? 'all' : null)
        // console.log(page, state);

        setPage(`${page}`)
        navigate(`${page === 'home' ? '/' : page}`, { replace: true })
        dispatch({
            type: 'navigate',
            page: page,
            data: {
                state,
                group,
                check: pageData.data.check
            }
        })
    }


    useEffect(() => {


        // console.log(pageData);
        // console.log("Render: ",pageData.page, ', State: ', pageData.data.state, pageData.data.group, pageData.data.check);
        pageNavigate(pageData.page, pageData.data.state, pageData.data.group, pageData.data.check)

    }, [pageData.data.state])

    // ---------------------------------------------------

    // const [anchorEl, setAnchorEl] = useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const [state, setState] = useState(false)


    return (
        <div className={`header ${page === 'home' ? 'home' : ''}`}>
            <div className="header-box">
                <div className="header-title" >
                    <Typography  sx={{fontSize: {xs: '20px', sm: '32px', md: '28px'}, display: { xs: page === 'home' ? 'none' : 'bloak', sm: 'block'}}}  className="header-title notranslate">Heaven Upon Rice Fields</Typography >
                </div>

                <Fragment >
                    <IconButton sx={{ display: { xs: 'block', md: 'none'}, color: 'white' }}  onClick={() => setState(!state)}><MenuIcon /></IconButton>
                    <SwipeableDrawer
                        anchor='right'
                        open={state}
                        onClose={() => setState(false)}
                        onOpen={() => setState(true)}
                    >
                        <SideHeader pageNavigate={pageNavigate} setState={setState}/>
                    </SwipeableDrawer>
                </Fragment>


                <Box 	sx={{ display: { xs: 'none', md: 'flex'} }} className="header-page-box">
                    <div className={`page-box ${page === 'home' ? 'active' : ''}`} onClick={() => pageNavigate('home')}>
                        <p className="page-title">Home</p>
                    </div>
                    <div className={`page-box ${page === 'rooms' ? 'active' : ''}`} onClick={() => pageNavigate('rooms', 'all')}>
                        <p className="page-title">Rooms</p>
                    </div>
                    <div className={`page-box ${page === 'history' ? 'active' : ''}`} onClick={() => pageNavigate('history')}>
                        <p className="page-title">History</p>
                    </div>
                    <div className={`page-box ${page === 'attraction' ? 'active' : ''}`} onClick={() => pageNavigate('attraction')}>
                        <p className="page-title">Attraction</p>
                    </div>
                    <div className={`page-box ${page === 'gallery' ? 'active' : ''}`} onClick={() => pageNavigate('gallery')}>
                        <p className="page-title">Gallery</p>
                    </div>
                    <div className={`page-box ${page === 'contact' ? 'active' : ''}`} onClick={() => pageNavigate('contact')}>
                        <p className="page-title">Contact</p>
                    </div>
                </Box>
            </div>
        </div>
    )
}
