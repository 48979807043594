import MinnariaImage from '../../../images/history/Minnaria.png'
import ImageBox from './ImageBox'

const Minneriya = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Minneriya National park</div>
                {/* <div className="attraction-park-discription" style={{ marginBottom: '30px' }}>Minneriya National Park is home to the world’s largest known gathering of Asian elephants. During this period, herds of up to
                    350 elephants are seen at the 8,890-hectare park within a few square kilometers of the Minneriya Tank. According to some
                    reports, there are as many as 700 elephants. In August and September each year, during the dry season, wild elephants
                    migrate from Wasgamuwa National Park to Minneriya National Park in search of food and shelter. Tourists visit Minneriya
                    National Park largely because of elephants, especially in the dry season.</div> */}

                {/* <div className='attraction-park-box'>
                    <h2>Wilpattu National Park</h2>
                    <div className="attraction-park-list">
                        <div>Location</div>
                        <div>– Approximately 188 kms away from capitol Colombo</div>
                        <div>Enterance Gate</div>
                        <div>– Hunuwilgama Enterance (Main Gate)</div>
                        <div>Park visiting hours</div>
                        <div>– 6 AM to 6 PM</div>
                        <div>Mode of safaris</div>
                        <div>– Only Jeep safaris</div>
                        <div>Best time of the day to visit the park</div>
                        <div>– 6 AM to 10 AM and 3 PM to 6 PM</div>
                        <div>Type of animals in the park</div>
                        <div>– Leopards, Elephants, Bears, Deer, Crocodiles, Reptiles and many verities of birds</div>
                        <div>Closest town to the park entrance</div>
                        <div>– Nochchiyagama, Puttalam and Anuradhapura</div>
                        <div>Best months to visit the park</div>
                        <div>– Throughout the year. But February to October is the prime time</div>
                        <div>How to come</div>
                        <div>– Hired private car or van</div>
                    </div>
                    <div className="attraction-park-discription">
                        &nbsp;Wilpattu sanctuary was decalred as a National Park in 1938, Wilpattu National Park is located on the west coast close to the
                        historical city of Anuradhapura .The dry zone jungle is thickly grown. Wilpattu Natonal Park is home for many villus, or natural
                        lakes which dot the landscape in the Wilpattu National Park. Except for two, These lakes contain rainwater, thus are important
                        for resident and migratory water-birds. <br></br>
                        &nbsp;The history of the park is also of interest with ancient ruins having been discovered in Wilpattu National Park. Queen named
                        “Kuweni” (considered to be the mother of the Sinhala race) is said to have lived in the place known as Kalli Villu. Historical
                        evidence also shows the fact that Prince Saliya, son of King Dutugemunu lived in Wilpattu over 2,000 years ago.
                    </div>
                </div> */}

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        Minneriya National Park is home to the world’s largest known gathering of Asian elephants. During this period, herds of up to
                        350 elephants are seen at the 8,890-hectare park within a few square kilometers of the Minneriya Tank. According to some
                        reports, there are as many as 700 elephants. In August and September each year, during the dry season, wild elephants
                        migrate from Wasgamuwa National Park to Minneriya National Park in search of food and shelter. Tourists visit Minneriya
                        National Park largely because of elephants, especially in the dry season.</div>
                </div>

                <div className='attraction-park-box'>
                    <h3>Minneriya National Park’s Other Animals</h3>
                    <div className="attraction-park-discription">
                        The park is an important habitat for Sri Lanka’s two endemic monkeys, the purple-faced langur and the toque macaque. Large
                        herbivorous mammals such as Sri Lankan sambar deer and Sri Lankan axis deer frequent the park. Rare and endangered
                        species such as the Sri Lankan leopard and Sri Lankan sloth bear inhabit Minneriya. Minneriya is one of the areas where the
                        gray slender loris is reportedly found in Sri Lanka.</div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Birds at Minneriya National Park</h3>
                    <div className="attraction-park-discription">
                        The Minneriya reservoir is an important habitat for large water birds such as the lesser adjutant, painted stork, and spot-billed
                        pelican. Minneriya is a dormitory for many resident as well as migrant bird species. Flocks of 2000 little cormorants have been
                        reported. The great white pelican, ruddy turnstone, and grey heron are the other water birds here. Among the endemic birds
                        are Sri Lanka jungle fowl, Sri Lanka hanging parrot, brown-capped babbler, Sri Lanka grey hornbill, black-crested bulbul, and
                        crimson-fronted barbet. The number of threatened birds recorded in this national park is 11
                    </div>
                </div>
                <div className='attraction-park-box'>
                    <h3>Amphibians and Reptiles at Minneriya National Park</h3>
                    <div className="attraction-park-discription">
                        Among the nine species of amphibians at Minneriya National Park are the endemic and endangered Slender Wood Frog and
                        the Common Tree Frog. Of the 25 species of reptiles recorded in the park, eight are endemic, including the red-lipped lizard.
                        Water and land monitors are also seen here. The Mugger Crocodile can be seen near the tank. Many species of freshwater fish
                        are found in the Minneriya reservoir.</div>
                </div>
                <div className='attraction-park-box' style={{ border: 'none', marginBottom: '0' }}>
                    <h3>Vegetation at Minneriya National Park</h3>
                    <div className="attraction-park-discription">
                        The vegetation of the park consists of tropical dry mixed evergreen forests, abandoned chaparral lands, grasslands, and
                        wetlands. The open grasslands and old chena lands are dominated by the many species of small shrubs</div>
                </div>

                <ImageBox imageUrl={MinnariaImage} />
            </div>
        </div>
    )
}

export default Minneriya
