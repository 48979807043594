import { Button } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CheckBookCpt from './CheckBookCpt'
import PayLater from './PayLater'
import PayNow from './PayNow'


const ShowPayment = ({ data }) => {

    return data.payMethod === 'now' ? <PayNow  data={data}/> : <PayLater data={data}/>
}

export default ShowPayment
