
// import Koneswaram from '../../../images/history/Koneswaram.jpg'
import LIGHTHOUSE from "../../../images/history/LIGHTHOUSE.jpg";
import Tank from "../../../images/history/Tank.jpg";
import BIRD from "../../../images/history/BIRD.jpg";
import KEERI from "../../../images/history/KEERI.jpg";
import Adam from "../../../images/history/Adam.png";
import DORIC from "../../../images/history/DORIC.jpg";
import ImageBox from './ImageBox'

const Mannar = () => {
    return (
        <div className='attraction-card-box'>
            <div className="attraction-card-msg-box">
                <div className="attraction-card-title">Mannar</div>

                <div className='attraction-park-box'>
                    <div className="attraction-park-discription">
                        The attractions in Mannar are wide and varied, due to its interesting geography and history, which makes it a place not to be missed when travelling north from Colombo city. From being featured in popular ancient myths to possessing some peculiar natural attractions, Mannar is sure to surprise you with so much as you explore the town.
                    </div>
                </div>

                <div className='attraction-park-box' style={{paddingBottom: '30px'}}>
                    <h3>TALAIMANNAR LIGHTHOUSE</h3>
                    <div className="attraction-park-discription">
                        A Beacon Between Two Countries
                        <br></br>
                        <br></br>
                        Situated approximately 30 kilometres from Mannar’s main town on Mannar Island, the Talaimannar Lighthouse was built in the early 20th century, in the year 1915. It stands at 19 meters tall, featuring a round cylindrical tower with a painted white façade, topped with a lantern and gallery. It is attached to the Thalaimannar Pier, which once served as the terminal of a ferry service to India. However, a cyclone in 1964 destroyed the terminal, and the route was subsequently terminated. Today, all that remains of a connection to India is Adam’s Bridge, a stretch of limestone shoals that begins at Mannar Island and disappears under the water in the direction of Pamban Island in India.
                        <br></br>
                        <br></br>
                        Mannar is one of the warmest places in Sri Lanka, and the island is connected to the mainland via a 3-kilometre causeway. During ancient times, when Mannar’s pearl fishery industry was booming, the town was known as one of the biggest suppliers of pearls in the world. Kings and queens scattered across different continents wore pearls that were supplied from Mannar for more than 2000 years; however, the overuse of the area depleted its sources centuries ago, killing its pearling industry
                    </div>
                    <ImageBox imageUrl={LIGHTHOUSE} />
                </div>
                <div className='attraction-park-box' style={{paddingBottom: '30px'}}>
                    <h3>Tank GIANT'S TANK</h3>
                    <div className="attraction-park-discription">
                        Home to the legendary Yoda Wewa (“Yoda” is the Sinhalese word for “Giant”), the Giant’s Tank Sanctuary is a wildlife sanctuary covering an area of 10,700 acres in the Northern Province of Sri Lanka. It is located approximately 20 kilometres southeast of Mannar Island and was officially designated as a sanctuary in the mid-20th century, in the year 1954.
                        <br></br>
                        <br></br>
                        <h4>Possible Origins of the Tank</h4>
                        There are several hypotheses as to the origins of the tank. According to one theory, the Giant’s Tank is the same tank that was built by the ancient Sri Lankan King Dhatusena in the 5th century and subsequently restored by King Parakramabahu I in the 12th century. Another theory assumes that the tank was built by the Naga people, an ancient tribe that once inhabited the island.
                        <br></br>   <br></br>
                        <h4>The Tank During the Colonial Era</h4>
                        In the 18th century, the administration of that period’s Dutch governor considered renovating the tank, but those plans never came to fruition. The Dutch and subsequent British irrigation engineers who studied the tank came to the conclusion that it had been created with a flawed design; however, when the tank was nevertheless restored, it soon became apparent that it was yet another example of Sri Lanka’s great ancient irrigation systems.
                    </div>
                    <ImageBox imageUrl={Tank} />
                </div>
                <div className='attraction-park-box' style={{paddingBottom: '30px'}}>
                    <h3>MANNAR BIRD SANCTUARY</h3>
                    <div className="attraction-park-discription">
                        Spanning an area of more than 4,800 hectares, the Mannar Bird Sanctuary (also known as the Vankalai Lagoon or Vankalai Sanctuary) is home to a vast array of migratory birds. The area was declared a sanctuary by the Department of Wildlife Conservation in 2008, and provides a variety of different ecosystems (mangroves, salt marshes, lagoons, waterholes, grasslands and more) for the birds to peacefully live in. In 2010, the sanctuary was declared a Ramsar Site, marking it as a wetland site of international importance under the Ramsar Convention. The area is known to provide exceptional feeding and living habitats for its large waterbird population, hosting more than 20,000 waterbirds during the migratory season.
                        <br></br>   <br></br>
                        <h4>Possible Origins of the Tank</h4>
                        Several rare birds can be spotted here, including the spot-billed duck, the comb duck, the long-toed stint, peregrine falcons and the very rare Eastern Black-tailed Godwit. Visitors may also spot flamingos at the site. Nearly 150 different species of birds are reported to have been spotted at the sanctuary, and the site’s marine ecosystem is said to support more than 60 species including fish, turtles, dugongs and crocodiles. When the thousands of birds that migrate to Sri Lanka arrive, their first stop is the Mannar region – particularly the bird sanctuary. Vankalai Lagoon is also their last staging point before they leave the country at the end of the season.
                    </div>
                    <ImageBox imageUrl={BIRD} />
                </div>
                <div className='attraction-park-box'>
                    <h3>ARIPPU DUTCH FORT</h3>
                    <div className="attraction-park-discription">
                        <br></br>  
                        <h4>Origins of the Fort</h4>
                        Located approximately 20 kilometres south of the Mannar Fort on Mannar Island, the Arippu Dutch Fort is a small square-shaped bastion fort. Originally built by the Portuguese at Arippu, the fort was eventually fortified by the Dutch during their occupation of the island in the mid-17th century. The fort was also known as the Allirani Fort due to legends of the Tamil queen, Alli Raani, who once reigned over Mannar. Her fortress is said to have been located where the ruins of Arippu Fort now stand.                        <br></br>   <br></br>
                        <h4>British Occupation</h4>
                        During the British occupation of the island, the Arippu Fort was converted into accommodation for officers working under Frederick North, the first British Governor of Ceylon. North built his official summer residence, the Doric, at the beachfront that was adjacent to this fort. The British officers who occupied the fort were mainly used to oversee the pearl fisheries that operated in the area, as the industry was an extremely lucrative one at the time before its resources were eventually depleted due to overfishing. Afterwards, the fort was converted into a guest house, but this was abandoned once the decade-long civil war engulfed the country.
                        <br></br>   <br></br>
                        The district of Mannar is best visited between the months of July and September, or between December and February, where the weather is fairly pleasant. During the summer period between April and June, temperatures in the area skyrocket to nearly unbearable levels, and so it is recommended to avoid visiting during that time.
                    </div>
                    {/* <ImageBox imageUrl={DUTCH} /> */}
                </div>
                <div className='attraction-park-box' style={{paddingBottom: '30px'}}>
                    <h3>KEERI BEACH</h3>
                    <div className="attraction-park-discription">
                        When considering the many beautiful beaches that Sri Lanka has to offer, the country’s southern and western coasts receive the lion’s share of attention. Some beach towns have been dedicated to the enjoyment of its visitors and have thus attracted throngs of tourists to flock to its golden shores. However, untouched tropical beaches that are secluded and pristine, like Keeri Beach, can be found around the entire country. Mannar Island is connected to the northern mainland through a causeway. A mere five kilometres from its main town, Keeri Beach is known to locals for its sunny location and excellent swimming spots. The beach is bordered by a small forest of palm trees that is home to several tribes of monkeys, so if choosing Keeri Beach for a picnic, guests should ensure that no food items are left behind for the monkeys to steal. Remember to apply copious amounts of sunscreen before frolicking in the refreshing waters!
                    </div>
                    <ImageBox imageUrl={KEERI} />
                </div>
                <div className='attraction-park-box'>
                    <h3>SHRINE OF OUR LADY OF MADHU</h3>
                    <div className="attraction-park-discription">
                        The Shrine of Our Lady of Madhu is considered the holiest Catholic shrine in Sri Lanka, possessing a history that goes back for more than 400 years. Situated in the Mannar district, this Roman Catholic shrine to the Virgin Mary is a major centre of worship and pilgrimage for the country’s Tamil and Sinhalese Catholic population.
                        <br></br>   <br></br>
                        <h4>Story Behind the Sacred Shrine</h4>
                        After the Dutch invasion of Sri Lanka, the persecution of the Catholic Church in 1670 forced a number of Catholic families to flee from their homes in the coastal town of Manthai; despite their haste to escape, they carried the statue of Mary from their local church with them. Meanwhile, around 700 Catholics migrated south from Jaffna into the Vanni forests. The two communities came across each other in Madhu, where they decided to build a new shrine using the statue of Mary that the Catholic families had rescued.
                    </div>
                </div>
                <div className='attraction-park-box' style={{paddingBottom: '30px'}}>
                    <h3>ADAM'S BRIDGE</h3>
                    <div className="attraction-park-discription">
                        An amazing geographical feature which is the single remaining evidence that prove Sri Lanka was connected to India by land. The Adam's bridge is a narrow strip of land with the Indian Ocean by its side. The bridge is 48 km in length and it separates the Gulf of Mannar from Palk Strait. Records say that the bridge was fully over the sea before it was destroyed in a cyclone a few hundred years ago. The Adam's bridge appears in Indian legends such as Rama and Islamic legends such as Adam. It is believed in Islam that the Adams bridge was used for the religious significant Adam to continue from India to Adam's peak. Sources say that the bridge may have been constructed by Sri Lankans themselves.
                    </div>
                    <ImageBox imageUrl={Adam} />
                </div>
                <div className='attraction-park-box' style={{paddingBottom: '0', borderBottom: 'none'}}>
                    <h3>THE DORIC AT ARIPPU</h3>
                    <div className="attraction-park-discription">
                        Located approximately 40 kilometers south of Mannar Island, the Doric Bungalow (also known simply as The Doric) at Arippu was once the home of the first British Governor of Ceylon: Frederick North, the 5th Earl of Guildford.
                        <br></br>   <br></br>
                        <h4>Purpose of its Construction and Intriguing Design</h4>
                        Constructed at the beginning of the 19th century between 1801 and 1804, the house was intended by the governor to help the revival of Sri Lanka’s pearl fisheries. The two-story building came to be known as “The Doric” due to the presence of its columns, which were designed in the image of the ancient Greek Doric order. The building’s façade was decorated with lime plaster, using the lime of burnt oyster shells that subsequently gave it a marble-like appearance.
                        <br></br>   <br></br>
                        <h4>Its Original Features</h4>
                        On the ground floor of the building were four small bedrooms, each at one of its corners, while a large staircase occupied the main central space. On the upper floor were two rooms: one was used as a dining room that could seat at least twenty people, while the other was designated as the governor’s bedchamber. After being used as the governor’s residence, the building was handed over for the use of other officials and government agents, particularly for the supervision of pearl fishery.
                        <br></br>   <br></br>
                        Today, the Doric has been declared a protected archaeological monument. Its exposure to the weather conditions of its beachside cliff location has left the building in an impressive state of ruins
                    </div>
                    <ImageBox imageUrl={DORIC} />
                </div>

                {/* <ImageBox imageUrl={Koneswaram} /> */}
            </div>
        </div>
    )
}

export default Mannar
