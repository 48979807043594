import { Box } from '@mui/material'
import React from 'react'

const ImageBox = ({imageUrl}) => {
    return (
        <div>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}  >
                {
                    imageUrl !== ''
                        ?
                        <div className="attraction-card-img-box-xs">
                            <img src={imageUrl} />
                        </div>
                        :
                        ''
                }
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                {
                    imageUrl !== ''
                        ?
                        <div className="attraction-card-img-box">
                            <img src={imageUrl} style={{ width: '100%' }} />
                        </div>
                        :
                        ''
                }
            </Box>
        </div>
    )
}

export default ImageBox
