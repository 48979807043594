import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import './availability.css'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { useState } from 'react'
import { Calendar } from 'react-date-range';
import { format } from "date-fns";
import dayjs from 'dayjs';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


const datePickerStyle = {

    width: '100%',
    borderRadius: '0px',
    borderStyle: 'none',
    "& .MuiInputBase-root": {
        borderStyle: "10px dotted rgba(45, 85, 255, 0.4)",
        borderRadius: '0',
        // fontSize: '20px'
    },
    "& .MuiInputBase-input": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        padding: '10px 15px'
    },

}

export const Availability = ({ dispatch }) => {

    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);


    const date = new dayjs();
    const dateAfter = new dayjs(date)

    const [dateIn, setDateIn] = useState(date)
    const [dateOut, setDateOut] = useState(new dayjs(dateAfter.add(1, 'day')))

    const [cInCalender, setCInCalender] = useState(false)
    const [cOutCalender, setCOutCalender] = useState(false)

    const handleChange = (e) => {
        console.log(e);
    }



    return (
        <Box className='avaiilablity notranslate'  sx={{ border: { xs: '1px solid rgb(196, 196, 196)', md: 'none' } }} >

            {/* <p className="availability-title">Booking Your Room</p> */}
            <p className="availability-title">Book Your Stay</p>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="check-input-box">



                    <div className="check-input-title">Check In</div>
                    <DatePicker format='DD/MM/YYYY' disablePast sx={datePickerStyle} defaultValue={dateIn} onChange={e => {


                        const date = new dayjs(`${e.get('month') + 1}-${e.get('date')}-${e.get('year')}`)
                        setDateIn(date)

                        console.log(date.diff(dateOut, 'day'));


                        if (date.diff(dateOut, 'day') >= 0) {
                            console.log('chenged', date.add(1, 'day'));
                            setDateOut(date.add(1, 'day'))
                        }



                    }} />

                </div>

                <div className="check-input-box">
                    <div className="check-input-title">Check Out</div>

                    <DatePicker format='DD/MM/YYYY' minDate={dateIn.add(1, 'day')} value={dateOut} sx={datePickerStyle} onChange={e => {
                        // const date = new dayjs(`${e.get('month')}-${e.get('date')}-${e.get('year')}`)
                        setDateOut(new dayjs(`${e.get('month') + 1}-${e.get('date')}-${e.get('year')}`))
                    }} />


                </div>

                <div className="inputs-a-c">

                    <FormControl fullWidth>
                        <p className="check-input-title">Adults</p>
                        {/* <InputLabel id="demo-simple-select-label">Adults</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={adults}
                            // label="Adults"
                            onChange={(e) => setAdults(e.target.value)}
                            sx={{height: '50px' , borderRadius: '0', fontSize: '22px'}}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <p className="check-input-title">Children</p>
                        {/* <InputLabel id="demo-simple-select-label">Children</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={children}
                            // label="Children"
                            onChange={(e) => setChildren(e.target.value)}
                            sx={{height: '50px' , borderRadius: '0', fontSize: '22px'}}
                        >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                        </Select>
                    </FormControl>

                    {/* <div className="input-box-title">
                        <p className="input-title">Adults</p>
                        <div className="input-input-box">
                            <Button variant="outlined" className='input-box' sx={{ borderRadius: '0', minWidth: '40px' }} onClick={() => setAdults((adults > 1) ? (adults - 1) : 1)} >-</Button>
                            <div className="input-box"><p className='inputs-titles'>{adults}</p></div>
                            <Button variant="outlined" className='input-box' sx={{ borderRadius: '0', minWidth: '40px' }} onClick={() => setAdults(adults + 1)}>+</Button>
                        </div>
                    </div>
                    <div className="input-box-title">
                        <p className="input-title">Children (5-11yr)</p>
                        <div className="input-input-box">
                            <Button variant="outlined" className='input-box' sx={{ borderRadius: '0', minWidth: '40px' }} onClick={() => setChildren((children > 0) ? (children - 1) : 0)}>-</Button>
                            <div className="input-box"><p className='inputs-titles'>{children}</p></div>
                            <Button variant="outlined" className='input-box' sx={{ borderRadius: '0', minWidth: '40px' }} onClick={() => setChildren(children + 1)}>+</Button>
                        </div>
                         </div> */}
                </div>

                <Button onClick={() => {


                    const date1 = new Date(dateIn);
                    const date2 = new Date(dateOut);
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    // console.log(diffDays);

                    dispatch({
                        type: 'change',
                        page: 'rooms',
                        data: {
                            state: 'ability',
                            group: null,
                            check: {
                                in: new dayjs(dateIn),
                                out: new dayjs(dateOut),
                                nights: diffDays,
                                adults,
                                children
                            }
                        }
                    })
                }} variant="contained" className='check-btn' sx={{ borderRadius: '0' }} >Check Availability</Button>

            </LocalizationProvider>
        </Box>
    )
}
