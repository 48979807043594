import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import './service.css'
import parking from '../../images/service/free-parking.png'
import laundry from '../../images/service/laundry.png'
import restaurant from '../../images/service/restaurant.png'
import airport from '../../images/service/airport.png'


export const Service = () => {

  const theme = useTheme();

  const mq_xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box className="service-loc-container">
      <Box className="service-loc-container-box" sx={{ padding: { xs: '0px 0px', md: '10px 0px' } }}>
        {/* <Stack className='service-loc-box' direction={{ xs: 'column-reverse', sm: 'row' }} style={mq_xs ? { padding: '10px 10px', border: 'none', margin: '0' } : { padding: '10px 40px', border: 'none' }}> */}
        <div className="loc-contact-itemf" style={{ color: 'black', textAlign: 'center', fontWeight: 900, margin: '10px' }}>
          <div className='service-icon'>
            <img src={airport} alt="" srcSet="" height={100} style={{ border: '3px solid #1c1c1c', borderRadius: '8px' }} />
          </div>
          <p className='service-title'>Airport Pickup</p>
        </div>
        <div className="loc-contact-itemf" style={{ color: 'black', textAlign: 'center', fontWeight: 900, margin: '10px' }}>
          <div className='service-icon'>
            <img src={parking} alt="" srcSet="" width={100} />
          </div>
          <p className='service-title'>Free Parking</p>
        </div>
        <div className="loc-contact-itemf" style={{ color: 'black', textAlign: 'center', fontWeight: 900, margin: '10px' }}>
          <div className='service-icon'>
            <img src={laundry} alt="" srcSet="" width={100} />
          </div>
          <p className='service-title'>Laundry Service</p>
        </div>
        <div className="loc-contact-itemf" style={{ color: 'black', textAlign: 'center', fontWeight: 900, margin: '10px' }}>
          <div className='service-icon'>
            <img src={restaurant} alt="" srcSet="" width={100} />
          </div>
          <p className='service-title'>Restaurant</p>
        </div>

        {/* </Stack> */}
      </Box>
    </Box>
  )
}
