import { Backdrop, Box, Button, CircularProgress, Modal, Typography, useTheme } from '@mui/material';

import React, { useState } from 'react'
import CheckBookCpt from './CheckBookCpt';

import getBookingDetails from './bookingDetails'
import axios from 'axios';
import target from '../../db/target';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 230, sm: 400 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const PayLater = ({ data }) => {


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showComplete, setShowComplete] = useState(false)
    const [openLoading, setOpenLoading] = useState(false);
    const [bookingId, setBookingId] = useState(0)

    const theme = useTheme()


    function payLaterHandler() {
        // console.log(data);

        setOpenLoading(true)
        setOpen(false)

        // const details = getBookingDetails(bookingId, data)
        // console.log(details);

        axios.get(target + 'api/client/make/booking-id')
            .then(result => {

                const id = result.data.bookingId

                setBookingId(id)


                const details = getBookingDetails(id, data, false)

                console.log(details);

                console.log({
                    discouttax: data.discouttax,
                    book: details.book,
                    mail: details.book.info.email
                });

                setTimeout(() => {
                    setOpenLoading(false)
                    setShowComplete(true)
                }, 2000);





                axios.post(target + 'api/client/make/book-paylater', {
                    details,
                    discouttax: data.discouttax
                })
                    .then(res => {
                        console.log(res);
                        setTimeout(() => {
                            setOpenLoading(false)
                            setShowComplete(true)
                            data.setShowBack(false)
                        }, 2000);
                    })
                    .catch(error => {
                        console.error(error);
                        alert(error.message)
                        setOpenLoading(false)
                    })

            })
            .catch(error => {
                console.error(error);
                alert(error.message)
                setOpenLoading(false)
            })
    }

    return <div>

        {
            showComplete
                ?
                <CheckBookCpt bookingId={bookingId} email={data.cinfo.email} />
                :
                <div style={{ display: 'flex', justifyContent: 'end' }}><Button variant='contained' onClick={handleOpen} style={{ marginBottom: '10px' }}>Book</Button></div>
        }

        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h5" sx={{ marginBottom: '5 px', fontSize: { xs: '1.25rem', sm: '1.5rem' } }}> Confirm your booking </Typography>
                <Typography id="modal-modal-description" sx={{ marginLeft: '15px', fontSize: { xs: '.825rem', sm: '1rem' } }} >
                    you will recive a email to <span style={{ fontWeight: 'bold' }}>"{data.cinfo.email}"</span>
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px', marginTop: '20px' }}>
                    <Button size='' onClick={handleClose} >Close</Button>
                    <Button size='small' variant='contained' onClick={payLaterHandler} >Book Now</Button>
                </Box>
            </Box>

        </Modal>


        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
        // onClick={setOpenLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
}

export default PayLater
