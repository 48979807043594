import { Typography } from '@mui/material'
import React from 'react'

const CheckDiscount = () => {
    return (
        <div className='co-cart-box-dis'>
            <div className="co-cart-title-box">
                <Typography variant="h6" >Offers</Typography>
                <Typography variant="subtitle2">Deluxe Double Room with Balcony & Rice Paddy View</Typography>
            </div>
            <div className="co-cart-price-box">
                {/* <p>2 Nights</p> */}
                <Typography variant="h5" >US $25</Typography>
            </div>
        </div>
    )
}

export default CheckDiscount
