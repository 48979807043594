import React, { useEffect, useState } from 'react'
import CheckBookCpt from './CheckBookCpt';
import { Backdrop, Box, Button, CircularProgress, Modal, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import getBookingDetails from './bookingDetails'
import target from '../../db/target';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 230, sm: 400 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// const styleCpt = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: { xs: 230, sm: 400 },
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     borderRadius: '20px',
//     boxShadow: 24,
//     // p: 4,
// };


const PayNow = ({ data }) => {


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showComplete, setShowComplete] = useState(false)
    const [showPGW, setShowPGW] = useState(true)
    const [openLoading, setOpenLoading] = useState(false);
    const [bookingId, setBookingId] = useState(0)
    const [sessionId, setSessionId] = useState()

    const [payState, setPayState] = useState(true)

    const theme = useTheme()


    ///////////////////////////////////////------------------------------------------------------------

    window.beginCallBack = function (data) {
        console.log(data);
        setOpenLoading(true)
    }

    // window.successCallback = function (data) {
    //     console.log(data);

    //     axios.get('api/client/make/book', {
    //         params: {
    //             resultIndicator: data['resultIndicator']
    //         }
    //     })
    //         .then(res => {
    //             console.log(res);

    //             setOpenLoading(false)
    //         })
    //         .catch(err => console.log(err))

    // }

    window.errorCallback = function (error) {
        console.error(error);

        setOpenLoading(false)
    }

    window.timeoutCallback = function () {
        console.error('Time Out');

        setOpenLoading(false)
    }

    window.closedWindowCallBack = function () {
        console.log('Window Closed');
        // setOpenLoading(false)
    }

    ///////////////////////////////////////------------------------------------------------------------

    window.showComplete = function (results) {

        // axios.get('api/client/make/book', {
        //     params: {
        //         resultIndicator: results.resultIndicator
        //     }
        // })
        //     .then(res => {
        //         console.log(res);
        //         showComplete(true)
        //     })
        //     .catch(err => console.log(err))


        data.setShowBack(false)
        makeBook(results.resultIndicator)
        
        
        console.log('payment is success');
       

    }

    // function refreshHandler() {

    //     setTimeout(() => {

    //         window.config(sessionId)
    //         window.run()
    //         setOpenLoading(false)

    //     }, 500);

    // }

    function makeBook (success_indicator) {

        // console.log(success_indicator);

        let details = getBookingDetails(bookingId, data, true)

        const {book, cbook} = details

        details = {
            book : {
                ...book,
                sessionId,
                successIndicator: success_indicator,
            },
            cbook,
            
        }


        axios.post(target + 'api/client/make/book-paynow',  {
            details,
            discouttax: data.discouttax
        })
            .then(res => {

                console.log(res);

                setTimeout(() => {
                    setShowComplete(true)
                }, 2000);

            })
            .catch(error => {
                console.log(error);
                alert(error.message)
                setOpenLoading(false)
            })


    }


    function payNowHandler() {

        setOpenLoading(true)

        // const session = {
        //     "interaction.merchant.name": details.book.info.name,
        //     "order.id": details.book.id,
        //     "order.amount": details.book.price.total,
        //     "order.currency": "LKR",
        //     "order.description": `~Room Booking of HURF~\nbooking-id: #${details.book.id}\nname: ${details.book.info.name}\nemail: ${details.book.info.email}\nnumber: ${details.book.info.phone}`,
        // }

        // setOpen(true)


        axios.get(target + 'api/client/make/booking-id')
            .then(result => {

                const id = result.data.bookingId

                setBookingId(id)
                // console.log(result.data);

                const details = getBookingDetails(id, data, true)

                const session = {
                    "interaction.merchant.name": details.book.info.name,
                    "order.id": details.book.id,
                    "order.amount": details.book.price.total,
                    "order.currency": "USD",
                    "order.description": `~Room Booking of HURF~\nbooking-id: #${details.book.id}\nname: ${details.book.info.name}\nemail: ${details.book.info.email}\nnumber: ${details.book.info.phone}`,
                }

                // console.log(session);

                axios.post(target + 'api/client/payment/session-id', session)
                    .then(result => {
                        // console.log(result);

                        setPayState(false)

                        setSessionId(result.data['session.id'])

                        setTimeout(() => {

                            window.config(result.data['session.id'])
                            window.run()
                            setOpenLoading(false)

                        }, 500);

                    })
                    .catch(error => {
                        console.error(error);
                    })
            })
            .catch(error => {
                console.error(error);
                alert(error.message)
                setOpenLoading(false)
            })
    }



    return (
        <div>

            {
                payState ? <div style={{ display: 'flex', justifyContent: 'end' }}><Button variant='contained' onClick={payNowHandler} style={{ marginBottom: '10px' }}>Pay & Book</Button></div> : ''
            }

            {
                showComplete
                    ?
                    <CheckBookCpt bookingId={bookingId} email={data.cinfo.email} />
                    :
                    payState ? '' : <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid rgb(214, 214, 214)' }} id="hco-embedded"></div>
            }


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            // onClick={setOpenLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PayNow
