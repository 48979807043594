import { Availability } from '../../componets/Availability/Availability'
import { RoomGroups } from '../../componets/RoomGroups/RoomGroups'
import { Testimonials } from '../../componets/Testimonials/Testimonials'
import './home.css'

import Image from '../../images/Picture3.png'
import ImageHero from '../../images/hero/herobg1.jpg'
import { HeroMessage } from '../../componets/HeroMessage/HeroMessage'
import { Stack } from '@mui/material'
import BgVideo from '../../assets/hotel-video.mp4'
import { Packages } from '../../componets/Packages/Packages'
import { Tours } from '../../componets/Tours/Tours'
import { Location } from '../../componets/Location/Location'
import { useRef, useState } from 'react'
import Offers from '../../componets/Offers/Offers'
import OffersPage from '../../componets/OffersPage/OffersPage'
import { Service } from '../../componets/Service/Service'


const LoadingHome = () => {

    return <div className='loadinghome'>
        <div className='loading-contaner'>
            <svg viewBox="0 0 100 100">
                {/* <defs>
                    <filter id="shadow">
                        <feDropShadow dx="0" dy="0" stdDeviation="1.5"
                            flood-color="#104A5D" />
                    </filter>
                </defs> */}
                <circle id="spinner" style={{ fill: 'transparent', stroke: '#206A5D', strokeWidth: '7px', strokeLinecap: 'round', filter: 'url(#shadow)' }} cx="50" cy="50" r="45" />
            </svg>
        </div>
        <h2 className='loadinghome-title'>Heaven Upon Rice Fields</h2>
    </div>
}




export const Home = ({ dispatch , status}) => {


    const {loading, setLoading, showOffers, setShowOffers} = status

    const [heroVideo, setHeroVideo] = useState(false)


    const tours = useRef()
    const rooms = useRef()
    const explore = useRef()

    function scrolToSection(element) {
        console.log(element);
        window.scrollTo({
            top: element.current.offsetTop - 20,
            behavior: 'smooth'
        })
    }
    setTimeout(() => {
        setLoading(false)
    }, 500)

    return (
        <div className='home-page'>

           {loading ? <LoadingHome /> : ''} 
           {showOffers ? <Offers setShowOffers={setShowOffers}/> : ''} 
           <OffersPage />




            <div className="hero-section">

                <div className="hero-section-img">
                    <img src={Image} alt="bg" className="main-bg-img" />
                    {
                        heroVideo ? '' :  <img src={ImageHero} alt="bg" className="main-bg-hero-img" />
                    }
                   
                    <video className="main-bg-video" autoPlay muted loop onLoadedData={() => setHeroVideo(true)}>
                        <source src={BgVideo} type='video/mp4' />
                    </video>

                    <div className="main-bg-black"></div>
                </div>

                <Stack
                    className="main-screen"
                    direction={{ xs: 'column-reverse', sm: 'row' }}
                    sx={{ marginTop: { xs: '70px', sm: '150px' } }}
                >
                    <Availability dispatch={dispatch} />
                    <HeroMessage />
                </Stack>

            </div>

            <Packages scrolToSection={scrolToSection} elements={{ tours, rooms }}  />
            <div ref={rooms} className="services-title" >
                <h1>Services</h1>
            </div>
            <Service />

            <div ref={rooms} className="testimonials-title-box" style={{ marginTop: '60px' }}>
                <h1>Rooms & Suites</h1>
            </div>
            
            <RoomGroups dispatch={dispatch} />
            <Tours ref={tours} />
            <Testimonials />
            <Location />
        </div>
    )
}
