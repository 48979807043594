import { Card } from '@mui/material'
import React from 'react'

const AttractionCard = ({ data }) => {

  const { imageUrl, left, title, description, bottom } = data

  return left
    ?
    <div className='attraction-card-box'>
      {
        imageUrl !== ''
          ?
          <div className="attraction-card-img-box">
            <img src={imageUrl} width={400} />
          </div>
          :
          ''
      }
      <div className="attraction-card-msg-box">
        <div className="attraction-card-title">{title}</div>
        <div className="attraction-card-description">{description}</div>
      </div>
    </div>
    :

    (
      bottom
        ?
        <div className='attraction-bottom-card-box'>
          <div className="attraction-card-msg-box">
            <div className="attraction-card-title">{title}</div>
            <div className="attraction-bottom-card-description"><p>{description}</p></div>
          </div>
          {/* {
            imageUrl !== ''
              ?
              <div className="attraction-card-img-box">
                <img src={imageUrl} width={400} />
              </div>
              :
              ''
          } */}
          {/* <div className='attraction-card-bottom-img'>
              Hello
          </div> */}
          {
            imageUrl[0] !== ''
              ?
              <div className="attraction-card-img-box">
                <img src={imageUrl} style={{width: '100%'}} />
              </div>
              :
              ''
          }
        </div>
        :
        <div className='attraction-card-box'>
          <div className="attraction-card-msg-box">
            <div className="attraction-card-title">{title}</div>
            <div className="attraction-card-description"><p>{description}</p></div>
          </div>
          {
            imageUrl !== ''
              ?
              <div className="attraction-card-img-box">
                <img src={imageUrl} width={400} />
              </div>
              :
              ''
          }
        </div>

    )

}

export default AttractionCard
