import { Box, Container, Divider } from '@mui/material'
import { CartCard } from '../CartCard/CartCard'
import './cart.css'
import { TotalCartCard } from '../TotalCartCard/TotalCartCard'
import { useState } from 'react'





export const Cart = ({ check, cart, setCart, methods, available, packagesprices }) => {

  // console.log(packagesprices);

  let adults = check.adults
  let children = check.children
  let totalAdults = 0
  let totalChildren = 0
  let onecp = {}

  cart = cart.map((item, index) => {

    let oneperson = false
    var lastPrice = item.price

    packagesprices.map((pkgitem, index) => {
      if (pkgitem.title === item.packageTitle) {
        onecp = pkgitem
        // console.log(pkgitem);
        if (adults === 1) {
          lastPrice -= pkgitem.oneperson
          oneperson = true
        }

        if (children > 0) {
          lastPrice += pkgitem.onechild
        }
      }
    })

    // console.log('Data', onecp);

    totalAdults += adults
    totalChildren += children

    return {
      ...item,
      adults,
      children,
      oneperson,
      price: lastPrice,
      initprice: item.price
    }
  })

  return (
    <div className="cart-box">
      {cart.map(({ packageTitle, title, price, adults, children, oneperson }, index) => {
        return (
          <Box key={index}>
            <CartCard setCart={setCart} packageTitle={packageTitle} title={title} price={price} index={index} oneperson={oneperson} adults={adults} children={children} onecp={onecp}/>
            <Divider />
          </Box>)
      })}
      <Container sx={{ width: 1, height: '10px', backgroundColor: '#f5f5f5' }}></Container>
      <TotalCartCard cart={cart} methods={methods} check={check} available={available} adults={totalAdults} children={totalChildren} />
    </div>
  )
}