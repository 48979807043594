import './offerspage.css'
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material'
import { useEffect, useState } from 'react';
import target from '../../db/target';
import axios from 'axios';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const OffersPage = () => {

    const [showingImages, setShowingImages] = useState([])


    const [offerspage, setOffersPage] = useState(false)
    const [showOffers, setShowOffers] = useState(false)

    const endPoint = target + 'images/rooms/'

    useEffect(() => {
        axios.get(target + 'api/client/offers/all')
            .then(res => {



                const showingArray = res.data.filter(img => {
                    return img.state === 'showing'
                })

                // console.log('offers', showingArray.length);
                if (showingArray.length > 0) {
                    setShowOffers(true)
                } else {
                    setShowOffers(false)
                }

                setShowingImages(showingArray)

            })
            .catch(error => {
                console.log(error)
            })
    }, [])



    return (<div>



        {
            showOffers ?
                <div className='offerspage-btn-box' onClick={() => setOffersPage(true)}>
                    <h3>Offers</h3>
                </div>
                :
                ''
        }


        {offerspage ?
            <div className='offerspage'>

                <div className='offerspage-topup-box'>
                    <div className='offerspage-topup-close'>
                        <h2 style={{ margin: '20px', marginLeft: '25px' }}>Offers from Heaven Upon Rice Fields</h2>

                        <IconButton onClick={() => setOffersPage(false)} >
                            <CloseIcon style={{ color: 'red' }} />
                        </IconButton>
                    </div>

                    <div className='offerspage-topup-items'>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 750: 2 }}
                            style={{ margin: '10px' }}
                        >
                            <Masonry gutter='10px' >
                                {showingImages.map((image, i) => (
                                    <img
                                        key={i}
                                        src={`${endPoint + image.name}`}
                                        style={{ width: "100%", display: "block" }}
                                        alt=""
                                    // onClick={() => viewImage.name(image, i)}
                                    />
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
            :
            ''}
    </div>
    )
}

export default OffersPage
