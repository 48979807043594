import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const data = {
    roomTitle: '',
    packageTitle: '',
    price: 0,
    adults: 0,
    children: 0,



}

const Card = ({ index, title, packageTitle, adults, children, initPrice, update, packagesprices }) => {
    // console.log(index);

    const cost = {
        oneperson: 10,
        children: 5,
        age: 6
    }



    const [_adults_, setAdults] = useState(adults)
    const [_children_, setChildren] = useState(children)
    const [_age_, setAge] = useState(0)

    const [roomPrice, setRoomPrice] = useState(initPrice)

    const _adults = useRef()
    const _children = useRef()
    const _age = useRef()

    const initValues = () => {

        var lastPrice = initPrice

        // if (packageTitle !== "Room Only") {

        //     if (adults === 1) {
        //         lastPrice -= cost.oneperson
        //     }

        //     //     if (children > 0) {
        //     //         if (_age_ >= cost.age) {
        //     //             lastPrice += cost.children
        //     //         }
        //     //     }

        // }


        packagesprices.map((pkgitem, index) => {
            if (pkgitem.title === packageTitle) {
                if (adults === 1) {
                    lastPrice -= pkgitem.oneperson
                }

                if (children > 0) {
                    lastPrice += pkgitem.onechild
                }
            }
        })



        setRoomPrice(lastPrice)
    }

    const handleChange = (target, value) => {

        value = parseInt(value)

        const limit_age = 5

        // console.log(_children_, _age_, _adults_, target, value);


        // console.log('inti - ', initPrice);
        // console.log(_adults_, _children_, _age_);

        var lastPrice = initPrice
        
        packagesprices.map((pkgitem, index) => {
            if (pkgitem.title === packageTitle) {
                // console.log(pkgitem.title);
                if (target === 'a') {
                    if (value === 1) {
                        lastPrice -= pkgitem.oneperson
                    }
    
                    if (_children_ > 0) {
                        if (_age_ >= limit_age) {
                            lastPrice += pkgitem.onechild
                        }
                    }
                } else if (target === 'c') {
    
                    if (_adults_ === 1) {
                        lastPrice -= pkgitem.oneperson
                    }
    
                    if (value > 0) {
                        if (_age_ >= limit_age) {
                            lastPrice += pkgitem.onechild
                        }
                    }
                } else {
                    if (_adults_ === 1) {
                        lastPrice -= pkgitem.oneperson
                    }
    
                    if (_children_ > 0) {
                        if (value >= limit_age) {
                            lastPrice += pkgitem.onechild
                            
                        }
                    }
                }
            }
        })


        // if (packageTitle !== "Room Only") {



        //     if (target === 'a') {
        //         if (value === 1) {
        //             lastPrice -= cost.oneperson
        //         }

        //         if (_children_ > 0) {
        //             if (_age_ >= cost.age) {
        //                 lastPrice += cost.children
        //             }
        //         }
        //     } else if (target === 'c') {

        //         if (_adults_ === 1) {
        //             lastPrice -= cost.oneperson
        //         }

        //         if (value > 0) {
        //             if (_age_ >= cost.age) {
        //                 lastPrice += cost.children
        //             }
        //         }
        //     } else {
        //         if (_adults_ === 1) {
        //             lastPrice -= cost.oneperson
        //         }

        //         if (_children_ > 0) {
        //             if (value >= cost.age) {
        //                 lastPrice += cost.children
        //             }
        //         }
        //     }
        // }


        update({ index, _adults_, _children_, _age_, target, value, initPrice, lastPrice })

        // console.log({ index, _adults_, _children_, _age_, target, value, initPrice, lastPrice });
       
        setRoomPrice(lastPrice)

    }


    useEffect(() => {
        initValues()
    }, [])


    return <div className='cardlist-card' style={{ marginBottom: '10px' }}>
        <h4>{title}</h4>
        <div style={{ margin: '0 0 0 10px' }}>
            <p className='cardlist-card-package'>Package - {packageTitle}</p>
            <div className='cardlist-card-item' >
                <div>
                    <FormControl sx={{ minWidth: 80, marginRight: '20px', marginTop: '15px' }} size='small'>
                        {/* <div className='cardlist-card-input'> */}
                        <InputLabel>adults</InputLabel>

                        <Select
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select"
                            size='small'
                            // variant='standard'
                            sx={{ textAlign: 'center' }}
                            inputRef={_adults}
                            value={_adults_}
                            label="Adults"
                            onChange={(e) => {
                                setAdults(e.target.value)
                                handleChange('a', e.target.value)
                            }}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                        </Select>
                        {/* </div> */}
                    </FormControl>

                    <FormControl sx={{ minWidth: 80, marginRight: '10px', marginTop: '15px' }} size='small'>
                        <InputLabel>children</InputLabel>

                        <Select
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select"
                            size='small'
                            // variant='standard'
                            sx={{ textAlign: 'center' }}
                            inputRef={_children}
                            value={_children_}
                            label="Children"
                            onChange={(e) => {
                                setChildren(parseInt(e.target.value))
                                handleChange('c', e.target.value)
                            }}
                        >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                        </Select>
                    </FormControl>

                    {_children_ > 0 ?
                        <FormControl sx={{ minWidth: 80, marginTop: '15px' }} size='small'>
                            <InputLabel>age</InputLabel>

                            <Select
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select"
                                size='small'
                                // variant='standard'
                                sx={{ textAlign: 'center' }}
                                inputRef={_age}
                                value={_age_}
                                label="Age"
                                onChange={(e) => {
                                    setAge(parseInt(e.target.value))
                                    handleChange('g', e.target.value)
                                }}
                            >
                                <MenuItem value={0}>below 1 year</MenuItem>
                                <MenuItem value={1}>1 year</MenuItem>
                                <MenuItem value={2}>2 year</MenuItem>
                                <MenuItem value={3}>3 year</MenuItem>
                                <MenuItem value={4}>4 year</MenuItem>
                                <MenuItem value={5}>5 year</MenuItem>
                                <MenuItem value={6}>6 year</MenuItem>
                                <MenuItem value={7}>7 year</MenuItem>
                                <MenuItem value={8}>8 year</MenuItem>
                                <MenuItem value={9}>9 year</MenuItem>
                                <MenuItem value={10}>10 year</MenuItem>
                                <MenuItem value={11}>11 year</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        ''}


                </div>

                <div className='cardlist-card-item-price notranslate' style={{ minWidth: '100px', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                    <h3>US$ {roomPrice.toFixed(2)}</h3>
                </div>
            </div>
        </div>
    </div>
}

const CardList = ({ cartData, check, cartDataLast, setCartDataLast, packagesprices }) => {

    // console.log(cartData, check, cartDataLast);

    const cart = cartDataLast.cart




    const update = (data) => {

        var details = {}

        if (data.target === "a") {
            details = {
                adults: data.value,
                price: data.lastPrice,
                oneperson: (data.value === 1 ? true : false)
            }
        } else if (data.target === "c") {
            details = {
                children: data.value,
                price: data.lastPrice
            }
        } else if (data.target === "g") {
            details = {
                price: data.lastPrice,
            }
        }

        cart[data.index] = {
            ...cart[data.index],
            ...details
        }

        // ------------------------------------
        var _a = 0;
        var _c = 0;
        var _t = 0;

        cart.map((item, index) => {
            _a += item.adults
            _c += item.children
            _t += item.price
        })

        // console.log(cart, _a, _c, _t);
        _t = _t * check.nights

        setCartDataLast({
            adults: _a,
            children: _c,
            total: _t,
            cart
        })

    }


    return (
        <div className='cardlist-box'>
            {/* <Card adults={1} children={1} initPrice={45} /> */}


            {
                cartData.cart.map((item, index) => {

                    return <Card key={index} index={index} packagesprices={packagesprices} update={update} adults={item.adults} children={item.children} initPrice={item.initprice} title={item.title} packageTitle={item.packageTitle} />
                })
            }


        </div>
    )
}

export default CardList
