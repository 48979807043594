import { Backdrop, Button, CircularProgress, Container } from '@mui/material'
import './totalcartcard.css'
import { Expand } from '@mui/icons-material'
import { useEffect, useState } from 'react';
import CBackdrop from '../CBackdrop/CBackdrop';

export const TotalCartCard = ({ cart, methods, check, available, adults, children }) => {

  // console.log('Total: ', check);



  // const selected = cart.length * 2;
  // const all = adults;
  let roomprice = 0

  cart.map(({ price }) => {
    roomprice += price
  })


  let totalprice = roomprice * check.nights

  const [_available, setAvailable] = useState(available)

  useEffect(() => {
    // console.log(available, check);
    setAvailable(available)
  }, [available, check.adults, check.children, check.in, check.out])


  return (
    <div className='total-cartcard'>
      <div className="total-cart-Details-box">
        <div className="total-cart-details">
          <p className="total-cart-title">Total Price</p>
          <p className="total-cart-description">{cart.length} rooms & {check.nights} nights</p>
          {/* <p className="total-cart-description">adults - {selected} of {all}</p> */}
          <p className="total-cart-description">adults - {adults}</p>
        </div>
        <div className="total-cart-price">US ${totalprice}</div>
      </div>


      <Button disabled={!(_available && cart.length > 0)} onClick={() => {
        methods.setCartData({
          total: totalprice,
          // adutls: selected,
          adults,
          children,
          cart,
        })
        methods.setMode(false)
      }} variant='contained' sx={{ width: 1, boxShadow: 'none', }}>NEXT</Button>

    </div>
  )
}
